import React from 'react';
import Layout from '../../../components/Layout';
import { Helmet } from 'react-helmet';
import YoutubeUrlApp from '../../../components/campaign/apps/YoutubeUrlApp';

const YoutubeUrl: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Youtube Giveaway Comment Picker</title>
      </Helmet>
      <YoutubeUrlApp />
    </Layout>
  );
};

export default YoutubeUrl;