import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Create Giveaways & Promotions Apps</title>
      </Helmet>
    </Layout>
  );
};

export default Dashboard;