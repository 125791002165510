interface NotificationMethods {
  setMessage: (message: string, status: boolean | null) => void;
  removeMessage: (index: number) => void;
  subscribe: (listener: (messages: { message: string; status: boolean | null }[]) => void) => void;
  unsubscribe: () => void;
}

let messages: { message: string; status: boolean | null }[] = [];
let messageSubscriber: ((messages: { message: string; status: boolean | null }[]) => void) | null = null;

const NotificationService = (): NotificationMethods => {

const setMessage = (message: string, status: boolean | null = null): void => {
  messages.push({ message, status });
  notifySubscriber();
};

const removeMessage = (index: number): void => {
  messages.splice(index, 1);
  notifySubscriber();
};

const subscribe = (listener: (messages: { message: string; status: boolean | null }[]) => void): void => {
  messageSubscriber = listener;
  if (messages.length > 0) {
    listener([...messages]); // Notify immediately with existing messages
  }
};

const unsubscribe = (): void => {
  messageSubscriber = null;
};

const notifySubscriber = (): void => {
  if (messageSubscriber) {
    messageSubscriber([...messages]);
  }
};

return {
  setMessage,
  removeMessage,
  subscribe,
  unsubscribe,
};
};

export default NotificationService;