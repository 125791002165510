import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SocialPlatformEnum as SocialPlatform } from '../helpers/SocialPlatformEnum';

const RedirectHandler: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { socialPlatform } = useParams<{ socialPlatform: SocialPlatform }>();
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const fragmentParams = new URLSearchParams(window.location.hash.substring(1)); // Remove the leading '#'
  const accessToken = fragmentParams.get('access_token');

  useEffect(() => {
    if (socialPlatform && socialPlatform === SocialPlatform.Facebook) {
      if (code && state && state.includes(SocialPlatform.Facebook)) {

        localStorage.setItem('facebookCode', code);
      }
      else if (!code) {
        if (accessToken) {
          localStorage.setItem('tempFacebookAccessToken', accessToken);
        }
      }
    }

    window.close();
  }, []);

  return (
    <>
      <Helmet>
        <title>socialspicker.com</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-md text-center">
          <h2 className="text-2xl font-bold mb-6">OAuth connecting...</h2>
          <div className="mt-4 text-center">
          </div>
        </div>
      </div>
    </>
  );
};

export default RedirectHandler;