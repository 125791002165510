import React from 'react';
import NotificationService from '../../services/NotificationService';
import { useIsLoading } from '../../hooks/useIsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { SubmitHandler, useForm } from 'react-hook-form';
import loggerService from '../../services/LoggerService';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { useLocation } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface CampaignSearchByCodeFormInputs {
    code: string;
}

const CampaignSearchByCode: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<CampaignSearchByCodeFormInputs>();
    const notificationService = NotificationService();
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();

    const onSubmit: SubmitHandler<CampaignSearchByCodeFormInputs> = async (data) => {
        const handleSubmitForm = async () => {
            setIsLoading(true);
            setLoadingMessage(LoadingMessage.Message);

            try {

            } catch (error) {
                notificationService.setMessage('Failed, try again.', false);

                loggerService.error(error);
            }
            finally {
                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    return (
        <>
            <div className="flex flex-col items-center p-4 mt-4">
                <>
                    <h1 className="text-2xl font-bold mb-4">Search Giveaway Result By Code</h1>
                    <p className="text-center mb-4">Find the result of the giveaways with the giveaway code. Make sure the results are safe</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
                        <div className="flex items-center py-2">
                            <FontAwesomeIcon icon={faSearch} className="mr-2 text-primary" />
                            <input
                                type="text"
                                {...register('code', { required: 'CODE is required' })}
                                placeholder="Enter a Giveaway CODE"
                                className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                            />
                        </div>
                        <div className="w-full text-center py-1">
                            {errors.code && <p className="text-red-500 text-sm">{errors.code.message}</p>}
                        </div>
                        <button
                            type="submit"
                            className={`${getButtonPrimaryStyleClass(true)} w-full p-2`}
                            disabled={isLoading}
                        >
                            {isLoading ? loadingMessage : "Search"}
                        </button>
                    </form>
                </>
            </div>
        </>
    );
};

export default CampaignSearchByCode;