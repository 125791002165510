import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Link } from 'react-router-dom';
import { RoutesEnum as AppRoutes } from '../routes/RoutesEnum';
import { getButtonPrimaryStyleClass } from '../config/FormSettings';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-center bg-white pt-8 pb-8">
            <Helmet>
                <title>socialspicker.com - 404 Not Found</title>
            </Helmet>
            <Link to={AppRoutes.HOME} className="flex items-center mb-6">
                <img src="/assets/logoBlack.svg" alt="Logo" className="w-full h-auto max-w-3xs" />
            </Link>
            <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
            <p className="mb-8">The page you are looking for does not exist.</p>
            <button
                className={`${getButtonPrimaryStyleClass(false)} px-4 py-2`}
                onClick={() => navigate(AppRoutes.HOME)}
            >
                Go to Home
            </button>
        </div>
    );
};

export default NotFound;