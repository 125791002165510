import React from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import CampaignSearchByCode from '../../components/campaign/CampaignSearchByCode';

const CampaignSearchByCodePage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Search Giveaway Result By Code</title>
      </Helmet>
      <CampaignSearchByCode />
    </Layout>
  );
};

export default CampaignSearchByCodePage;