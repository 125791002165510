import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/helpers/axiosInstance';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import NotificationService from '../../services/NotificationService';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { useReactTable, ColumnDef, getCoreRowModel, getPaginationRowModel, PaginationState, flexRender } from '@tanstack/react-table';
import { UserDataDTO } from '../../dto/UserDataDTO';
import SPPagination from '../../components/SPPagination';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { paginationSize } from '../../config/envConfig';
import { SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';
import { CampaignTypeEnum } from '../../helpers/CampaignTypeEnum';

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminDashboard: React.FC = () => {
  const notificationService = NotificationService();
  const [isSearchTerm, setIsSearchTerm] = useState<boolean>(false);
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage, setLoadingMessage } = useLoadingMessage();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [usersData, setUsersData] = useState<UserDataDTO[]>([]);
  const [usersDataStatistics, setUsersDataStatistics] = useState<{ admins: UserDataDTO[]; adminsEmail: string; totalCountUsers: number; totalCountVerifiedUsers: number; }>({ admins: [], adminsEmail: "", totalCountUsers: 0, totalCountVerifiedUsers: 0 });
  const [userAdmins, setUserAdmins] = useState<UserDataDTO[]>([]);
  const [campaignsDataStatistics, setCampaignsDataStatistics] = useState<{ totalCount: number; commentsCount: number; facebookCount: number; instagramCount: number }>({ totalCount: 0, commentsCount: 0, facebookCount: 0, instagramCount: 0 });
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(usersDataStatistics.totalCountUsers / paginationSize);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (isSearchTerm) {
      fetchData();
    }
  }, [isSearchTerm]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setLoadingMessage(LoadingMessage.Message);

      const response = await axiosInstance.get(ApiRoutes.GET_USERS, {
        authNeeded: true,
        params: {
          pageNumber: currentPage,
          pageSize: paginationSize,
          ...(isSearchTerm && { searchEmailOrFirstnameOrLastname: searchTerm })
        }
      } as CustomAxiosRequestConfig);

      setIsSearchTerm(false);

      const usersData: UserDataDTO[] = response.data.users.map((user: any) => ({
        Id: user.id,
        Email: user.email,
        FirstName: user.firstName,
        LastName: user.lastName,
        Verified: user.verified,
        Role: user.role,
        CampaignsCount: user.campaignsCount,
      }));

      setUsersData(usersData);

      const usersAdmin: UserDataDTO[] = response.data.usersAdmin.map((user: any) => ({
        Id: user.id,
        Email: user.email,
        FirstName: user.firstName,
        LastName: user.lastName,
        Verified: user.verified,
        Role: user.role,
      }));

      setUserAdmins(usersAdmin);

      const reponseGetCampaigns = await axiosInstance.get(ApiRoutes.GET_CAMPAIGNS_STATISTICS, { authNeeded: true } as CustomAxiosRequestConfig);

      setCampaignsDataStatistics({
        totalCount: reponseGetCampaigns.data.totalCount,
        commentsCount: reponseGetCampaigns.data.commentsCount,
        facebookCount: reponseGetCampaigns.data.facebookCount,
        instagramCount: reponseGetCampaigns.data.instagramCount,
      });

      const adminsEmail = usersAdmin
        .map(usersAdmin => usersAdmin.Email)
        .join(', ');
      setUsersDataStatistics({
        admins: usersAdmin,
        adminsEmail: adminsEmail,
        totalCountUsers: response.data.totalCountUsers,
        totalCountVerifiedUsers: response.data.totalCountVerifiedUsers
      });
    } catch (error: any) {
      if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
        notificationService.setMessage(error.response.data.message, error.response.data.status);
    }
    finally {
      setIsLoading(false);
    }
  };

  const chartData = {
    labels: ['Verified', 'Not Verified'],
    datasets: [
      {
        data: [usersDataStatistics.totalCountVerifiedUsers, usersDataStatistics.totalCountUsers - usersDataStatistics.totalCountVerifiedUsers],
        backgroundColor: ['#4CAF50', '#F44336'],
      },
    ],
  };

  const roleChartData = {
    labels: ['Admin', 'User'],
    datasets: [
      {
        data: [userAdmins.length, usersDataStatistics.totalCountUsers],
        backgroundColor: ['#2196F3', '#FFC107'],
      },
    ],
  };

  const chartCampaignSocialPlatform = {
    labels: [SocialPlatformEnum.Facebook, SocialPlatformEnum.Instagram],
    datasets: [
      {
        data: [campaignsDataStatistics.facebookCount, campaignsDataStatistics.instagramCount],
        backgroundColor: ['#0766ff', '#fc03c0'],
      },
    ],
  };

  const chartCampaignCampaignType = {
    labels: [CampaignTypeEnum.Comments],
    datasets: [
      {
        data: [campaignsDataStatistics.commentsCount],
        backgroundColor: ['#0766ff'],
      },
    ],
  };

  const handlePageChange = (selected: { selected: number }) => {
    setCurrentPage(selected.selected + 1);
  };

  const columns = useMemo<ColumnDef<UserDataDTO>[]>(
    () => [
      {
        accessorKey: 'Id',
        header: 'ID',
      },
      {
        accessorKey: 'Email',
        header: 'Email',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'FirstName',
        header: 'First Name',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'LastName',
        header: 'Last Name',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'Verified',
        header: 'Verified',
        cell: info => (info.getValue() ? 'Yes' : 'No'),
      },
      {
        accessorKey: 'Role',
        header: 'Role',
        cell: info => info.getValue()
      },
      {
        accessorKey: 'CampaignsCount',
        header: 'Campaigns',
        cell: info => info.getValue()
      }
    ],
    []
  );

  const table = useReactTable({
    data: usersData || [],
    columns,
    state: { pagination },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Admin Dashboard</title>
      </Helmet>
      <div className="mx-auto p-4">
        <h1 className="text-2xl font-bold">Admin Dashboard {isLoading && (loadingMessage)}</h1>

        <div className="mt-6">
          <p className="text-lg font-semibold">Accounts:</p>
        </div>
        <div className="mt-2">
          <h2 className="text-base">Admins: {isLoading ? loadingMessage : usersDataStatistics.adminsEmail || 'No admins available'}</h2>
        </div>
        <div className="mt-2">
          <p className="text-base">Accounts Created: {isLoading ? loadingMessage : usersDataStatistics.totalCountUsers}</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <div className="max-h-40 w-full aspect-w-1 aspect-h-1">
            <Pie data={chartData} />
          </div>
          <div className="max-h-40 w-full aspect-w-1 aspect-h-1">
            <Pie data={roleChartData} />
          </div>
        </div>

        <div className="mt-6">
          <p className="text-lg font-semibold">Campaigns:</p>
        </div>
        <div className="mt-2">
          <p className="text-base">Campaigns Created: {isLoading ? loadingMessage : campaignsDataStatistics.totalCount}</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <div className="max-h-40 w-full aspect-w-1 aspect-h-1">
            <Pie data={chartCampaignSocialPlatform} />
          </div>
          <div className="max-h-40 w-full aspect-w-1 aspect-h-1">
            <Pie data={chartCampaignCampaignType} />
          </div>
        </div>

        <div className="mt-6">
          {isLoading ? (
            <div>{loadingMessage}</div>
          ) : (
            <>
              <div className="flex mt-6">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={`${getInputTextStyleClass()} w-full p-2`}
                />
                <button className={getButtonPrimaryStyleClass(false)} onClick={() => setIsSearchTerm(true)}>
                  Search
                </button>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border">
                  <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <tr key={headerGroup.id} className="border-b">
                        {headerGroup.headers.map(header => (
                          <th key={header.id} className="py-2 px-4 bg-gray-200 border-r text-left">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map(row => (
                      <tr key={row.id} className="border-b">
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id} className="py-2 px-4 border-r">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-4">
                <SPPagination
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            </>
          )}
        </div>

      </div>
    </Layout>
  );
};

export default AdminDashboard;