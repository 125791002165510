import NotificationService from "../services/NotificationService";

const notificationService = NotificationService();

export const canSubmit = (waitTime: number = 30000): boolean => {
  const lastSubmitted = localStorage.getItem('lastSubmitted');
  const now = new Date().getTime();

  if (lastSubmitted && now - parseInt(lastSubmitted) < waitTime) {
    const remainingTime = Math.ceil((waitTime - (now - parseInt(lastSubmitted))) / 1000);
    notificationService.setMessage(`Please wait ${remainingTime} seconds before trying again.`, false);
    return false;
  }

  localStorage.setItem('lastSubmitted', now.toString());
  return true;
};