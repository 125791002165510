import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { faCalendar, faUserFriends, faTimes, faUser, faRemove, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { CampaignModel } from '../models/CampaignModel';
import { useIsLoading } from '../hooks/useIsLoading';
import axiosInstance, { CustomAxiosRequestConfig } from '../auth/helpers/axiosInstance';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import NotificationService from '../services/NotificationService';
import LoadingScreen from '../components/loadings/LoadingScreen';
import { getButtonPrimaryStyleClass, getButtonSuccessStyleClass } from '../config/FormSettings';
import { getSocialPlatformEnumNameValueList, SocialPlatformEnum } from '../helpers/SocialPlatformEnum';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '../routes/RoutesEnum';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { prettifyNumbers } from '../helpers/StringsHelper';
import SPPagination from '../components/SPPagination';
import { paginationSize } from '../config/envConfig';

const Dashboard: React.FC = () => {
  const notificationService = NotificationService();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState<CampaignModel[] | null>(null);
  const { isLoading, setIsLoading } = useIsLoading();
  const [totalCountCampaigns, setTotalCountCampaigns] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(totalCountCampaigns / paginationSize);

  useEffect(() => {
    const getCampaigns = async () => {
      try {
        setIsLoading(true);

        const response = await axiosInstance.get(ApiRoutesEnum.GET_CAMPAIGNS_BY_USER,
          {
            authNeeded: true,
            params: {
              pageNumber: currentPage,
              pageSize: paginationSize
            }
          } as CustomAxiosRequestConfig);

        const campaigns: CampaignModel[] = response.data.campaigns
          .map((campaign: any) => ({
            Title: campaign.title,
            SocialPlatform: campaign.socialPlatform,
            CampaignTypeEnum: campaign.campaignTypeEnum,
            Date: campaign.date,
            ParticipantsCount: campaign.participantsCount,
            Author: campaign.author,
          }));

        setCampaigns(campaigns);
        setTotalCountCampaigns(response.data.totalCountCampaigns);

        setIsLoading(false);
      }
      catch (error: any) {
        if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
          notificationService.setMessage(error.response.data.message, error.response.data.status);

        setIsLoading(false);
      }
    };

    getCampaigns();

  }, [currentPage]);

  const handlePageChange = (selected: { selected: number }) => {
    setCurrentPage(selected.selected + 1);
  };

  const handleCreate = () => {
    navigate(RoutesEnum.APPS);
  };

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Dashboard</title>
      </Helmet>
      {/* Event List Section */}
      <div className="mt-6">
        {isLoading ? (
          <>
            <LoadingScreen
            loadingMessage='Loading campaigns...'
            />
          </>
        ) : (
          <>
            {campaigns && campaigns.length > 0 ? (
              <>
                {/* Filters Section */}
                <div className="flex flex-col md:flex-row md:justify-between p-4 mb-4 bg-white rounded-lg shadow-md">
                  <div className="flex flex-row w-full">

                    <div className="flex flex-col space-y-1 flex-grow mr-2">
                      <label htmlFor="type" className="text-sm font-medium">Type</label>
                      <select id="type" className="form-select block max-w-56 p-1 mt-1 bg-white border border-gray-300 rounded-md shadow-sm">
                        <option value="all">All</option>
                        {getSocialPlatformEnumNameValueList().map((item: any, index: any) =>
                          <React.Fragment key={index}>
                            <option value={item.value}>{item.name}</option>
                          </React.Fragment>
                        )}
                      </select>
                    </div>

                    <div className="flex items-center ml-auto">
                      <button onClick={handleCreate} className={`${getButtonSuccessStyleClass(false)}`}>
                        Create
                      </button>
                    </div>

                  </div>
                </div>
                {/* Filters Section - end */}

                <div className="list-group list-group-flush w-full h-full min-h-96">
                  {campaigns.map((campaign, index) =>
                    <div key={index} className="list-group-item flex items-center mb-1 justify-between bg-white shadow-md hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white group">
                      <div className="rounded-lg p-6 flex flex-col items-center w-full h-full">

                        <div className="w-full pb-4 text-base font-semibold group-hover:text-white">
                          {campaign.Title}
                        </div>

                        {/*content*/}
                        <div className="w-full flex items-center group-hover:text-white">
                          <div className={`${campaign.SocialPlatform === SocialPlatformEnum.Instagram &&
                            ("bg-instagram")}
                          flex-shrink-0 mr-4 text-white w-10 h-10 flex items-center justify-center rounded-full`}>
                            {campaign.SocialPlatform === SocialPlatformEnum.Instagram &&
                              (<FontAwesomeIcon icon={faInstagram} />)}
                          </div>
                          <div className="flex-1">
                            <div className="text-sm text-gray-500 group-hover:text-white">
                              <span className="mr-4">
                                <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                                {campaign.Date}
                              </span>
                              <span className="mr-4">
                                <FontAwesomeIcon icon={faUser} className="mr-1" />
                                {campaign.Author}
                              </span>
                              <span className="mr-4">
                                <FontAwesomeIcon icon={faUserFriends} className="mr-1" />
                                {prettifyNumbers(campaign.ParticipantsCount)}
                              </span>
                            </div>
                          </div>
                          {/*dropdown*/}
                          <div className="ml-4 flex-shrink-0">
                            <div className="relative inline-block text-left">
                              <button
                                type="button"
                                className="inline-flex items-center p-2 text-gray-500 group-hover:text-white"
                                onClick={() => ""}
                              >
                                <FontAwesomeIcon icon={faPencil} className="text-lg" />
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center p-2 text-gray-500 group-hover:text-white"
                                onClick={() => ""}
                              >
                                <FontAwesomeIcon icon={faRemove} className="text-lg" />
                              </button>
                            </div>
                          </div>
                          {/*dropdown - end*/}
                        </div>
                        {/*content - end*/}

                      </div>
                    </div>
                  )}
                  <SPPagination
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                  />
                </div>

              </>
            ) : (
              <>
                <div className='pt-8 align-center text-center'>
                  <p className="text-center mb-4">Create your first campaign</p>
                  <button onClick={handleCreate} className={`${getButtonPrimaryStyleClass(false)}`}>
                    Create
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* Modals */}
      <div id="editModal" className="fixed inset-0 flex items-center justify-center p-4 z-50 hidden">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-sm">
          <div className="p-4 border-b border-gray-200 flex items-center justify-between">
            <h5 className="text-lg font-semibold">Edit</h5>
            <button type="button" className="text-gray-500 hover:text-gray-700" aria-label="Close" data-dismiss="modal">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="p-4">
            <div className="form-group">
              <label className="block text-sm font-medium">Title</label>
              <input type="text" className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm" />
            </div>
          </div>
          <div className="p-4 border-t border-gray-200 flex justify-end">
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;