import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const usePreventRefreshPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleWindowClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleWindowClose);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: PopStateEvent) => {
      if (!window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        navigate(location.pathname, { replace: true });
        event.preventDefault();
      }
    };

    window.addEventListener('popstate', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handleBeforeUnload);
    };
  }, [location, navigate]);

  return null;
};

export default usePreventRefreshPage;