import { useState, useEffect } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isUnder1200px, setIsUnder1200px] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768)
        setIsMobile(true);
      else
        setIsMobile(false);

      if (window.innerWidth < 1200)
        setIsUnder1200px(true);
      else
        setIsUnder1200px(false);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile, isUnder1200px };
};

export default useIsMobile;