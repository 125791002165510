import { useMatch } from 'react-router-dom';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';

const useIsAuthenticationPagesRoute = () => {
  const matchLogin = useMatch(AppRoutes.LOGIN);
  const matchSignup = useMatch(AppRoutes.SIGNUP);
  const matchResetPassword = useMatch(AppRoutes.RESET_PASSWORD);
  const matchSetNewPassword = useMatch(AppRoutes.SET_NEW_PASSWORD);
  const matchSetEmailVerify = useMatch(AppRoutes.SET_EMAIL_VERIFY);
  return matchLogin || matchSignup || matchResetPassword || matchSetNewPassword || matchSetEmailVerify;
};

export default useIsAuthenticationPagesRoute;