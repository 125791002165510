import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import axiosInstance from '../helpers/axiosInstance';
import NotificationService from '../../services/NotificationService';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import useIsMobile from '../../hooks/useIsMobile';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import useLoadRecaptcha from '../../hooks/useLoadRecaptcha';
import GoogleOauthAuth from './GoogleOauthAuth';
import FacebookInstagramOauthAuth from './FacebookInstagramOauthAuth';
import { useAuthContext } from '../contexts/AuthContext';
import { AuthLinkLabelsDisplayEnum } from '../helpers/AuthLinkLabelsDisplayEnum';
import AuthLinkLabels from './AuthLinkLabels';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';

interface SignupAuthFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface SignupAuthProps {
  isAuthModal?: boolean;
  setIsModalOpen?: (isModalOpen: boolean) => void;
  setAuthLinkLabelsDisplayEnum?: (authLinkLabelsDisplayEnum: AuthLinkLabelsDisplayEnum) => void;
  authLinkLabelsDisplayEnum?: AuthLinkLabelsDisplayEnum;
  handleCampaignStateData?: () => void;
}

const SignupAuth: React.FC<SignupAuthProps> = ({ isAuthModal = false, setIsModalOpen, setAuthLinkLabelsDisplayEnum, authLinkLabelsDisplayEnum = AuthLinkLabelsDisplayEnum.SIGNUP, handleCampaignStateData }) => {
  const { login } = useAuthContext();
  const notificationService = NotificationService();
  const { register, handleSubmit, formState: { errors } } = useForm<SignupAuthFormInputs>();
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage, setLoadingMessage } = useLoadingMessage();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const isRecaptchaLoaded = useLoadRecaptcha();

  const onSubmit: SubmitHandler<SignupAuthFormInputs> = async data => {
    const handleSubmitForm = async () => {
      try {
        setIsLoading(true);
        setLoadingMessage(LoadingMessage.Message);

        if (!isRecaptchaLoaded) {
          setLoadingMessage('Loading reCAPTCHA...');
          setTimeout(handleSubmitForm, 1000);
          return;
        }

        let recaptchaToken;
        try {
          recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string, { action: 'submit' });
        } catch (error) {
          notificationService.setMessage('reCAPTCHA failed. Please try again.', false);

          setIsLoading(false);

          return;
        }

        const formData = { ...data, recaptchaToken };

        const response = await axiosInstance.post(ApiRoutes.SIGNUP, formData, {

        });

        const { token, refreshToken } = response.data;

        await login(token, refreshToken);

        if (isAuthModal) {
          if (setIsModalOpen) {
            setIsModalOpen(false);
          }

          notificationService.setMessage("Connected", true);

          if (handleCampaignStateData) {
            handleCampaignStateData();
          }

          setIsLoading(false);

          //we don't need navigate, component will lose state
        }
        else {
          notificationService.setMessage("Connected", true);

          setIsLoading(false);

          navigate(AppRoutes.DASHBOARD);
        }
      } catch (error: any) {
        if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
          notificationService.setMessage(error.response.data.message, error.response.data.status);

        setIsLoading(false);
      }
    };

    handleSubmitForm();
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`bg-white p-8 rounded ${isAuthModal ? "" : "shadow-md"} w-full max-w-md`}>
        <h2 className="text-2xl font-bold mb-6">Signup</h2>
        <div className="w-full justify-center">
          <FacebookInstagramOauthAuth
            text="Signup with"
            setIsLoadingParent={setIsLoading}
            isLoadingParent={isLoading}
            isAuthModal={isAuthModal}
            setIsModalOpen={setIsModalOpen}
            handleCampaignStateData={handleCampaignStateData}
            showOnly={SocialPlatformEnum.Facebook}
          />
        </div>
        <div className="w-full flex justify-center">
          <GoogleOauthAuth
            text="Signup with Google"
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            isAuthModal={isAuthModal}
            setIsModalOpen={setIsModalOpen}
            handleCampaignStateData={handleCampaignStateData}
          />
        </div>
        <div className="flex items-center my-4">
          <hr className="flex-grow border-gray-300" />
          <span className="mx-2 text-gray-500">OR</span>
          <hr className="flex-grow border-gray-300" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`mb-4 flex ${isMobile ? 'flex-col space-y-4' : 'flex-row space-x-4'}`}>
            <div className="w-full md:w-1/2">
              <input
                type="text"
                {...register('firstName', { required: 'First name is required', maxLength: { value: 50, message: "First Name cannot exceed 50 characters" } })}
                maxLength={50}
                placeholder='First Name'
                className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
              />
              {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName.message}</p>}
            </div>
            <div className="w-full md:w-1/2">
              <input
                type="text"
                {...register('lastName', { required: 'Last name is required', maxLength: { value: 50, message: "Last Name cannot exceed 50 characters" } })}
                maxLength={50}
                placeholder='Last Name'
                className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
              />
              {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName.message}</p>}
            </div>
          </div>
          <div className="mb-4">
            <input
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address'
                },
                maxLength: { value: 100, message: "Email cannot exceed 100 characters" }
              })}
              maxLength={100}
              placeholder='Email'
              className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
          </div>
          <div className="mb-4">
            <input
              type="password"
              {...register('password', {
                required: 'Password is required',
                minLength: { value: 6, message: 'Password must be at least 6 characters' },
                maxLength: { value: 20, message: "Password cannot exceed 20 characters" }
              })}
              maxLength={20}
              placeholder='Password'
              className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
            />
            {errors.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
          </div>
          <button
            type="submit"
            className={`${getButtonPrimaryStyleClass(false)} w-full p-2`}
            disabled={isLoading}
          >
            {isLoading ? loadingMessage : "Signup"}
          </button>
        </form>
        <AuthLinkLabels
          setAuthLinkLabelsDisplayEnum={setAuthLinkLabelsDisplayEnum}
          authLinkLabelsDisplayEnum={authLinkLabelsDisplayEnum}
        />
      </div>
    </div>
  );
};

export default SignupAuth;