import React from 'react';
import Layout from '../../../components/Layout';
import { Helmet } from 'react-helmet';
import TwitterXUrlApp from '../../../components/campaign/apps/TwitterXUrlApp';

const TwitterXUrl: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Twitter / X Giveaway Comment Picker</title>
      </Helmet>
      <TwitterXUrlApp />
    </Layout>
  );
};

export default TwitterXUrl;