import React from 'react';
import { Link } from 'react-router-dom';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { AuthLinkLabelsDisplayEnum } from '../helpers/AuthLinkLabelsDisplayEnum';
import { getTextLinkStyleClass } from '../../config/FormSettings';

interface AuthLinkLabelsProps {
    setAuthLinkLabelsDisplayEnum?: (authLinkLabelsDisplayEnum: AuthLinkLabelsDisplayEnum) => void;
    authLinkLabelsDisplayEnum: AuthLinkLabelsDisplayEnum;
}

const AuthLinkLabels: React.FC<AuthLinkLabelsProps> = ({ setAuthLinkLabelsDisplayEnum, authLinkLabelsDisplayEnum }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            {authLinkLabelsDisplayEnum === AuthLinkLabelsDisplayEnum.LOGIN ? (
                <>
                    <div className="mt-4 text-center">
                        <Link to={AppRoutes.RESET_PASSWORD} className={`${getTextLinkStyleClass()}`}>I Forgot password</Link>
                    </div>
                    <div className="mt-4 text-center">
                        {!setAuthLinkLabelsDisplayEnum ? (<Link to={AppRoutes.SIGNUP} className={`${getTextLinkStyleClass()}`}>I Don't have an account</Link>)
                            :
                            (
                                <button onClick={() => { setAuthLinkLabelsDisplayEnum(AuthLinkLabelsDisplayEnum.SIGNUP) }} className={`${getTextLinkStyleClass()}`}>Signup</button>
                            )}
                    </div>
                </>
            ) : authLinkLabelsDisplayEnum === AuthLinkLabelsDisplayEnum.SIGNUP ?
                (
                    <>
                        <div className="mt-4 text-center">
                            {!setAuthLinkLabelsDisplayEnum ? (<Link to={AppRoutes.LOGIN} className={`${getTextLinkStyleClass()}`}>I have an account</Link>)
                                :
                                (
                                    <button onClick={() => { setAuthLinkLabelsDisplayEnum(AuthLinkLabelsDisplayEnum.LOGIN) }} className={`${getTextLinkStyleClass()}`}>Log in</button>
                                )}
                        </div>
                    </>
                ) : null}
        </div>
    );
};

export default AuthLinkLabels;