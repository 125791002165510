import React, { useState } from 'react';
import { getButtonSuccessStyleClass, getTextLinkStyleClass } from '../../config/FormSettings';
import { useDetectAdBlock } from 'adblock-detect-react';

const AdBlockModal: React.FC = () => {
    const isAdblock = useDetectAdBlock();
    const [isInstructionsOpened, setIsInstructionsOpened] = useState<boolean>(false);

    if (!isAdblock)
        return null;

    return (
        <div className="fixed w-full h-full inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
            <div className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">

                <div className="flex flex-col items-center">
                    <h3 className="text-body font-semibold text-xl mb-4 text-center">
                        We noticed that you have an ad blocker enabled
                    </h3>
                    <div className="text-left mx-auto">
                        <ul className="list-none space-y-4">
                            <li>
                                <div className="flex items-start space-x-2">
                                    <div>
                                        <div className="font-medium">Please, disable it to continue using our app</div>
                                        <div className="px-5 mt-4 text-center">
                                            <p className="text-muted">
                                                <button onClick={() => window.location.reload()} className={`p-2 ${getTextLinkStyleClass()}`}>Refresh the page</button><button onClick={() => setIsInstructionsOpened(!isInstructionsOpened)} className={`p-2 ${getTextLinkStyleClass()}`}>Instructions</button>
                                            </p>
                                            {isInstructionsOpened && (
                                                <div className="flex flex-col items-center justify-center p-4">
                                                    <img src="/assets/dab.gif" alt="disable" className="w-full h-auto" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AdBlockModal;