import React from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CloseButtonProps {
    text: string;
    onClick: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ text, onClick }) => {
    return (
        <>
            <button
                onClick={onClick}
                className={`max-w-md hover:underline`}>
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                {text}
            </button>
        </>
    );
};

export default CloseButton;