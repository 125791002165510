import React from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import SignupAuth from '../components/SignupAuth';
import { Link } from 'react-router-dom';

const Signup: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Signup</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center">
        <Link to={AppRoutes.HOME} className="mb-8">
          <img src="/assets/logoBlack.svg" alt="Logo" className="w-full h-auto max-w-3xs" />
        </Link>
      </div>
      <SignupAuth />
    </Layout>
  );
};

export default Signup;