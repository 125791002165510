import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faQuestion, faInfo, faStar, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useForm, SubmitHandler } from 'react-hook-form';
import loggerService from '../../services/LoggerService';
import { CampaignSettingsDTO } from '../../dto/CampaignSettingsDTO';
import { getButtonDisabledStyleClass, getButtonPrimaryStyleClass, getButtonSuccessStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { FormStateValidator } from '../../config/FormStateValidator';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { getRandomWinners } from '../../helpers/CampaignHelper';
import NotificationService from '../../services/NotificationService';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import { SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';
import { CampaignTypeEnum } from '../../helpers/CampaignTypeEnum';
import { CampaignModel } from '../../models/CampaignModel';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/helpers/axiosInstance';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import CampaignLoading from '../loadings/CampaignLoading';
import { AxiosResponse } from 'axios';
import { defaultMaxRetry, defaultWaitBetweenRequestsMs } from '../../config/envConfig';

interface CampaignSettingsFormInputs {
    title: string;
    winners: number;
    countdown: number;
    eliminateDuplicates: boolean;
}

const CampaignSettings: React.FC = () => {
    const {
        setCampaignSettingsDTO,
        filteredCampaignParticipants,
        setCampaignWinners,
        campaignSettingsDTO,
        setIsSavingSettings,
        isSavingSettings,
        isLoadingParticipants,
        isShufflingParticipants,
        setIsSettingsOpened,
        isSettingsOpened,
        setCampaignViewEnum,
        campaignStateDataDTO,
        navigateReferrer,
        setCampaignIdCurrent,
        setIsSavingCampaign,
        isSavingCampaign,
    } = useCampaignContext();
    const { userData } = useAuthContext();
    const [formStateValidator, setFormStateValidator] = useState<FormStateValidator[]>([]);
    const { register, handleSubmit, formState: { errors } } = useForm<CampaignSettingsFormInputs>();
    const notificationService = NotificationService();

    const [isInfoOpened, setIsInfoOpened] = useState(false);
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();

    //settings
    const [winners, setWinners] = useState(1);
    const [eliminateDuplicates, setEliminateDuplicates] = useState(false);
    const [overrideMostLikedComments, setOverrideMostLikedComments] = useState(false);
    const [filterByMention, setFilterByMention] = useState(false);
    const [mention, setMention] = useState<string>('');
    const [filterByHashtag, setFilterByHashtag] = useState(false);
    const [hashTag, setHashtag] = useState<string>('');
    const [countdown, setCountdown] = useState(10);
    //settings - end

    const handleFilterByMention = () => {
        setFilterByMention(prevState => !prevState);
    };

    const handleFilterByHashtag = () => {
        setFilterByHashtag(prevState => !prevState);
    };

    const handleEliminateDuplicates = () => {
        setEliminateDuplicates(prevState => !prevState);
    };

    const handleOverrideMostLikedComments = () => {
        setOverrideMostLikedComments(prevState => !prevState);
    };

    const handleIncrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
        setter(prev => Math.min(prev + 1, value));
    };

    const handleDecrement = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => {
        setter(prev => Math.max(prev - 1, 1));
    };

    const onSubmit: SubmitHandler<CampaignSettingsFormInputs> = async (data: any) => {
        const handleSubmitForm = async () => {
            try {
                setIsSavingSettings(true);

                if (!isFormStateValid()) {
                    return;
                }

                const campaignSettingsDTO: CampaignSettingsDTO = {
                    Title: data.title,
                    Winners: winners,
                    EliminateDuplicates: eliminateDuplicates,
                    FilterByMention: filterByMention,
                    Mention: mention,
                    FilterByHashtag: filterByHashtag,
                    Hashtag: hashTag,
                    OverrideMostLikedComments: overrideMostLikedComments,
                    Countdown: countdown,
                    ReplacementsMax: 50
                }

                setCampaignSettingsDTO(campaignSettingsDTO);
            }
            catch (error: any) {
                notificationService.setMessage("Failed to save, try again", false);

                loggerService.error(error);

                setIsSettingsOpened(true);

                setIsSavingSettings(false);
            }
        };

        handleSubmitForm();
    };

    useEffect(() => {
        const init = async () => {
            await saveCampaign();
        }

        if (isSavingCampaign) {
            init();
        }
    }, [isSavingCampaign]);

    const saveCampaign = async () => {
        try {
            if (isLoadingParticipants || isSavingSettings || isShufflingParticipants) {
                return;
            }

            setLoadingMessage("Saving campaign...");
            setIsLoading(true);

            const campaignWinners = await getRandomWinners(campaignStateDataDTO, campaignSettingsDTO, filteredCampaignParticipants);

            setCampaignWinners(campaignWinners);

            //we must make sure the state is always set
            if (campaignStateDataDTO && campaignSettingsDTO && userData && campaignWinners) {

                const formData: CampaignModel = {
                    Title: campaignSettingsDTO.Title,
                    SocialPlatform: campaignStateDataDTO.SocialPlatform,
                    CampaignType: campaignStateDataDTO.CampaignType,
                    Author: campaignStateDataDTO?.Username,
                    Post: campaignStateDataDTO?.Url,
                    ParticipantsCount: campaignStateDataDTO.ParticipantsCount,
                    Winners: [
                        ...campaignWinners
                    ],
                    UserId: userData.Id
                };

                const saveCampaignRetry = async (retries = defaultMaxRetry, delay = defaultWaitBetweenRequestsMs): Promise<AxiosResponse> => {
                    try {
                        const response = await axiosInstance.post(
                            ApiRoutesEnum.CREATE_CAMPAIGN,
                            formData,
                            { authNeeded: true } as CustomAxiosRequestConfig);
                        return response;
                    } catch (error) {
                        if (retries > 0) {
                            await new Promise(resolve => setTimeout(resolve, delay));

                            return saveCampaignRetry(retries - 1, delay);
                        }
                        else {
                            throw error;
                        }
                    }
                };

                const response: any = await saveCampaignRetry();
                setCampaignIdCurrent(response.data?.campaignId);

                setIsLoading(false);

                setCampaignViewEnum(CampaignViewEnum.Winner);
            }
            else {
                throw new Error();
            }
        }
        catch (error: any) {
            if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
                notificationService.setMessage(error.response.data.message, error.response.data.status);
            else
                notificationService.setMessage("Failed while saving campaign", false);

            setIsLoading(false);

            navigateReferrer();
        }
    };

    const isFormStateValid = () => {
        const validators: FormStateValidator[] = [];

        if (!winners || winners < 1 || winners > 50) {
            validators.push({
                Name: 'winners',
                Error: 'Winners must be between 1 and 50',
            });
        }

        setFormStateValidator(validators);

        return validators.length === 0;
    };

    return (
        <>
            {isLoading ? (
                <>
                    <CampaignLoading
                        loadingMessage={loadingMessage}
                    />
                </>
            ) : (
                <>
                    {isSettingsOpened ? (
                        <div className="card bg-gradient-to-r from-primary to-secondary rounded-lg shadow-md">
                            <div className="flex justify-between items-center px-4 py-2">
                                <div className="flex-1 text-white text-center text-lg font-semibold">
                                    <FontAwesomeIcon icon={faGear} className="mr-2 text-white" />
                                    Settings
                                </div>
                                <div className="text-white text-center text-base">
                                    <button onClick={() => { setIsInfoOpened(!isInfoOpened); }}>
                                        <FontAwesomeIcon icon={faQuestion} className="mr-2 text-white" />
                                        Help
                                    </button>
                                </div>
                            </div>
                            <div className="card-body py-3 px-4 border-t border-opacity-20 border-white rounded-lg">
                                <div className="flex flex-col items-center pt-0 pb-0">
                                    <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-0 mb-0">
                                        <div className="grid grid-cols-1 gap-4">
                                            <div className="col-span-1">
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Title: Giveaway / Promotion title</label>
                                                    </div>
                                                )}
                                                <input type="text" className={`${getInputTextStyleClass()} text-white placeholder-gray-400 w-full p-2 mt-1`}
                                                    {...register('title', { required: 'Title is required', maxLength: { value: 50, message: "Title cannot exceed 50 characters" } })}
                                                    maxLength={50}
                                                    placeholder='Title'
                                                />
                                                {errors.title && <p className="text-white bg-error text-center text-sm">{errors.title.message}</p>}
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-2">
                                                    <label className="block text-white">~ Winners</label>
                                                    <div className="ml-auto flex items-center">
                                                        <div className="relative flex items-center max-w-[8rem]">
                                                            <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleDecrement(setWinners, 50)}>
                                                                <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                            <input
                                                                type="text"
                                                                name="winners"
                                                                className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                                                                value={winners}
                                                                onChange={(e) => setWinners(Number(e.target.value))}
                                                            />
                                                            <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleIncrement(setWinners, 50)}>
                                                                <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formStateValidator
                                                    .filter(error => error.Name === "winners")
                                                    .map((error, index) => (
                                                        <p key={index} className="text-white bg-error text-center text-sm">{error.Error}</p>
                                                    ))}
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">~ Winners: The number of winners extracted for this Giveaway / Promotion, between 1-50</label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-3">
                                                    <label className="block text-white">Eliminate Duplicates</label>
                                                    <div className="ml-auto flex items-center switcher">
                                                        <label className="inline-flex items-center cursor-pointer">
                                                            <input type="checkbox" className="sr-only peer"
                                                                checked={eliminateDuplicates}
                                                                onChange={handleEliminateDuplicates}
                                                            />
                                                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
                                                            <span className="ms-3 text-sm font-medium text-white">{eliminateDuplicates ? 'YES' : 'NO'}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Eliminate Duplicates: All participants will have the same chance of winning without taking into account the number of times they comment</label>
                                                    </div>
                                                )}
                                            </div>

                                            {campaignStateDataDTO?.CommentsHasLikes &&
                                                (
                                                    <div className="col-span-1">
                                                        <div className="flex items-center mt-3">
                                                            <label className="block text-white">Most Liked Comments</label>
                                                            <div className="ml-auto flex items-center switcher">
                                                                <label className="inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" className="sr-only peer"
                                                                        checked={overrideMostLikedComments}
                                                                        onChange={handleOverrideMostLikedComments}
                                                                    />
                                                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
                                                                    <span className="ms-3 text-sm font-medium text-white">{overrideMostLikedComments ? 'YES' : 'NO'}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {isInfoOpened && (
                                                            <div>
                                                                <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                                <label className="text-sm text-white">Most Liked Comments: It will extract the winner or winners with the most liked comments, in descending order, based on the number of winners you select</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-3">
                                                    <label className="block text-white">Filter by @Mention</label>
                                                    <div className="ml-auto flex items-center switcher">
                                                        <label className="inline-flex items-center cursor-pointer">
                                                            <input type="checkbox" className="sr-only peer"
                                                                checked={filterByMention}
                                                                onChange={handleFilterByMention}
                                                            />
                                                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
                                                            <span className="ms-3 text-sm font-medium text-white">{filterByMention ? 'YES' : 'NO'}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {filterByMention && (
                                                    <>
                                                        <div className="pt-2 text-center">
                                                            <input type="text"
                                                                placeholder="Write a @Mention"
                                                                value={mention}
                                                                onChange={(e) => setMention(e.target.value)}
                                                                className={`${getInputTextStyleClass()} w-full max-w-md py-2 px-2 text-white`}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Filter by @Mention: The participants must @Tag a specific account you mention. You can automatically dismiss all participants that don't meet this requirement.</label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-3">
                                                    <label className="block text-white">Filter by @Hashtag</label>
                                                    <div className="ml-auto flex items-center switcher">
                                                        <label className="inline-flex items-center cursor-pointer">
                                                            <input type="checkbox" className="sr-only peer"
                                                                checked={filterByHashtag}
                                                                onChange={handleFilterByHashtag}
                                                            />
                                                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-white rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-success"></div>
                                                            <span className="ms-3 text-sm font-medium text-white">{filterByHashtag ? 'YES' : 'NO'}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {filterByHashtag && (
                                                    <>
                                                        <div className="pt-2 text-center">
                                                            <input type="text"
                                                                placeholder="Write a #Hashtag"
                                                                value={hashTag}
                                                                onChange={(e) => setHashtag(e.target.value)}
                                                                className={`${getInputTextStyleClass()} w-full max-w-md py-2 px-2 text-white`}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Filter by #Hashtag: You should apply this filter when one of the conditions is to type a #hashtag in the comment section.</label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-1">
                                                <div className="flex items-center mt-3">
                                                    <label className="block text-white">Countdown</label>
                                                    <div className="ml-auto flex items-center">
                                                        <div className="relative flex items-center max-w-[8rem]">
                                                            <button type="button" className="bg-transparent rounded-s-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleDecrement(setCountdown, 999)}>
                                                                <FontAwesomeIcon icon={faArrowDown} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                            <input
                                                                type="text"
                                                                name="countdown"
                                                                value={countdown} className={`h-11 text-center text-sm focus:ring-secondary block w-full py-2.5 text-white ${getInputTextStyleClass()}`}
                                                                onChange={(e) => setCountdown(Number(e.target.value))}
                                                            />
                                                            <button type="button" className="bg-transparent rounded-e-lg p-3 h-11 focus:ring-white focus:outline-none group"
                                                                onClick={() => handleIncrement(setCountdown, 999)}>
                                                                <FontAwesomeIcon icon={faArrowUp} className='w-3 h-full text-white group-hover:text-silver' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isInfoOpened && (
                                                    <div>
                                                        <FontAwesomeIcon icon={faInfo} className="mr-2 text-white" />
                                                        <label className="text-sm text-white">Countdown: The number in seconds to display the winners</label>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-1">
                                                <button
                                                    type="submit"
                                                    disabled={isSavingSettings ? true : false}
                                                    className={`${isSavingSettings ? getButtonDisabledStyleClass(true) : getButtonSuccessStyleClass(true)} w-full`}>
                                                    {isSavingSettings ?
                                                        "Applying filters" :
                                                        (isLoadingParticipants ?
                                                            "Loading participants..." : "Save")}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="col-span-1">
                                <button
                                    onClick={() => { setIsSettingsOpened(true); }}
                                    disabled={isLoadingParticipants ? true : false}
                                    type="button" className={`${getButtonPrimaryStyleClass(true)} w-full text-lg font-semibold`}>
                                    <FontAwesomeIcon icon={faGear} className="mr-2 text-white" />
                                    {isLoadingParticipants ? "Loading participants..." : isSavingSettings ? "Applying filters..." : "Settings"}
                                </button>
                            </div>

                            {filteredCampaignParticipants && filteredCampaignParticipants?.length > 1 && !isLoadingParticipants && !isSavingSettings && !isShufflingParticipants && (
                                <div className="fixed bottom-0 left-0 right-0 flex justify-center mb-4 z-50">
                                    <button
                                        type="button" onClick={() => setIsSavingCampaign(true)} className={`w-full max-w-md ${getButtonSuccessStyleClass(true)} `}
                                    >
                                        {filteredCampaignParticipants?.length && filteredCampaignParticipants?.length > 1 ? "Start" : "Need more participants..."}
                                    </button>
                                </div>
                            )}

                        </>
                    )}
                </>
            )}
        </>
    );
};

export default CampaignSettings;