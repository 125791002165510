import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { isDevelopmentReactStrictMode } from './config/envConfig';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

root.render(
  isDevelopmentReactStrictMode ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
);

serviceWorkerRegistration.register();
reportWebVitals();