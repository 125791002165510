import React from 'react';
import LoadingBar from './LoadingBar/LoadingBar';
import { Link } from 'react-router-dom';
import { getTextLinkStyleClass } from '../../config/FormSettings';

interface CampaignLoadingProps {
    loadingMessage: string;
}

const CampaignLoading: React.FC<CampaignLoadingProps> = ({ loadingMessage }) => {

    return (
        <>
            <div className="p-4 text-center">
                <div className="p-4 font-semibold text-base">
                    {loadingMessage}
                </div>
                <div>
                    <LoadingBar />
                </div>
                <div>
                    <p className="p-4">Do not reload the page</p>
                    <p className="pt-2">Follow us on instagram <Link className={`${getTextLinkStyleClass()}`} to="https://www.instagram.com/socialspicker" target='_blank'>@socialspicker</Link></p>
                    <p className="pt-2">Tag us when you announce the winners</p>
                </div>
            </div>
        </>
    );
};

export default CampaignLoading;