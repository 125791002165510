export const getButtonSuccessStyleClass = (isBold: boolean): string => {
  return `bg-success hover:bg-successHover text-white text-base p-2 rounded 
  ${isBold &&
    "font-semibold"
    }`;
};

export const getButtonErrorStyleClass = (isBold: boolean): string => {
  return `bg-error hover:bg-errorHover text-white text-base p-2 rounded
    ${isBold &&
    "font-semibold"
    }`;
};

export const getButtonPrimaryStyleClass = (isBold: boolean): string => {
  return `bg-primary hover:bg-secondary text-white text-base p-2 rounded
      ${isBold &&
    "font-semibold"
    }`;
};

export const getButtonDisabledStyleClass = (isBold: boolean): string => {
  return `bg-disabled text-white text-base p-2 rounded
      ${isBold &&
    "font-semibold"
    }`;
};

export const getInputTextStyleClass = (): string => {
  return "bg-transparent leading-tight focus:outline-none border-b border-gray-300";
};

export const getTextLinkStyleClass = (): string => {
  return "underline hover:no-underline text-base";
};

export const getBackgroundHover = (): string => {
  return "hover:bg-gradient-to-r hover:from-primary hover:to-secondary hover:text-white";
};

export const getBackgroundActive = (): string => {
  return "bg-gradient-to-r from-primary to-secondary text-white";
};