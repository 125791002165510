import axiosInstance, { CustomAxiosRequestConfig } from '../../helpers/axiosInstance';
import { ApiRoutesEnum as ApiRoutes } from '../../../routes/ApiRoutesEnum';
import NotificationService from '../../../services/NotificationService';
import { useIsLoading } from '../../../hooks/useIsLoading';
import { useLoadingMessage } from '../../../hooks/useLoadingMessage';
import { canSubmit } from '../../../helpers/RateLimitActionHelper';
import { useAuthContext } from '../../contexts/AuthContext';
import { getButtonPrimaryStyleClass } from '../../../config/FormSettings';

interface VerifyEmailModalProps {
  email: string;
  setIsVerifyEmailModalOpen: (isVerifyEmailModal: boolean) => void;
}

const VerifyEmailModal: React.FC<VerifyEmailModalProps> = ({ email, setIsVerifyEmailModalOpen }) => {
  const { login, logout } = useAuthContext();
  const notificationService = NotificationService();
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage } = useLoadingMessage();

  const handleVerifyEmail = async () => {
    try {
      setIsLoading(true);

      if (!canSubmit())
        return;

      const formData = { email };

      const response = await axiosInstance.post(
        ApiRoutes.EMAIL_VERIFY,
        formData,
        { authNeeded: true } as CustomAxiosRequestConfig);

      const { status, message } = response.data;

      notificationService.setMessage(message, status);
    } catch (error: any) {
      if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
        notificationService.setMessage(error.response.data.message, error.response.data.status);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCheckVerifyEmail = async () => {
    try {
      setIsLoading(true);

      if (!canSubmit()) {
        setIsVerifyEmailModalOpen(true);
        return;
      }

      const formData = { email };

      const response = await axiosInstance.post(
        ApiRoutes.CHECK_EMAIL_VERIFY,
        formData,
        { authNeeded: true } as CustomAxiosRequestConfig);

      const { token, refreshToken } = response.data;

      await login(token, refreshToken);

      notificationService.setMessage("Connected", true);

      setIsVerifyEmailModalOpen(false);
    } catch (error: any) {
      if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
        notificationService.setMessage(error.response.data.message, error.response.data.status);

      setIsVerifyEmailModalOpen(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleLogout = async () => {
    await logout();
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Verify your email</h2>
        <p className="mb-6">Please verify your acccount. Takes a few seconds</p>
        <button
          onClick={handleVerifyEmail}
          disabled={isLoading}
          className={`${getButtonPrimaryStyleClass(false)} px-4 py-2`}
        >
          {isLoading ? loadingMessage : 'Verify Email'}
        </button>
        <button
          onClick={handleCheckVerifyEmail}
          disabled={isLoading}
          className={`${getButtonPrimaryStyleClass(false)} px-4 py-2 ml-3`}
        >
          {isLoading ? loadingMessage : 'I Already Verified'}
        </button>
        <button
          onClick={handleLogout}
          className={`${getButtonPrimaryStyleClass(false)} px-4 py-2 ml-3`}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default VerifyEmailModal;