import { CampaignSettingsDTO } from "../dto/CampaignSettingsDTO";
import { CampaignStateDataDTO } from "../dto/CampaignStateDataDTO";
import { WinnerDTO } from "../dto/WinnerDTO";
import { RoutesEnum as AppRoutes } from "../routes/RoutesEnum";
import { CampaignTypeEnum } from "./CampaignTypeEnum";
import { SocialPlatformEnum } from "./SocialPlatformEnum";

export const setNavigateCampaignState = (navigate: (path: string) => void) => {
    navigate(AppRoutes.CAMPAIGN);
};

export const getRandomWinners = async (campaignStateDataDTO: CampaignStateDataDTO | null, campaignSettingsDTO: CampaignSettingsDTO | null, filteredCampaignParticipants: WinnerDTO[] | null): Promise<WinnerDTO[] | null> => {
    return new Promise((resolve, reject) => {

        if (filteredCampaignParticipants && campaignSettingsDTO) {
            //override settings
            let isOverride: boolean = false;
            let filteredOverride: WinnerDTO[] = [];

            if (campaignSettingsDTO.OverrideMostLikedComments) {
                filteredOverride = filteredCampaignParticipants
                    .filter(winner => winner.CommentLikeCount != null) // Filter out winners with null CommentLikeCount
                    .sort((a, b) => (b.CommentLikeCount ?? 0) - (a.CommentLikeCount ?? 0))// Sort in descending order
                    .slice(0, campaignSettingsDTO.Winners);

                isOverride = true;
            }

            if (isOverride) {
                resolve(filteredOverride);

                return;
            }
            //override settings - end

            const participantsCount = filteredCampaignParticipants.length;

            //selected winners based on settings winners count
            const winnersToSelect = Math.min(campaignSettingsDTO.Winners, participantsCount);

            const shuffleArray = (array: WinnerDTO[]) => {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
            };

            const shuffledParticipants = shuffleArray([...filteredCampaignParticipants]);
            
            const selectedUsernames = new Set<string>();
            const selectedWinners: WinnerDTO[] = [];

            for (const participant of shuffledParticipants) {
                if (selectedWinners.length >= winnersToSelect) break;
                if (!selectedUsernames.has(participant.Username)) {
                    selectedWinners.push(participant);
                    selectedUsernames.add(participant.Username);
                }
            }

            resolve(selectedWinners);

            return;
        }
    });
};

export const replaceRandomWinner = async (campaignStateDataDTO: CampaignStateDataDTO | null, campaignSettingsDTO: CampaignSettingsDTO, username: string, campaignWinners: WinnerDTO[] | null, replacedFilteredCampaignParticipants: WinnerDTO[] | null): Promise<WinnerDTO | null> => {
    return new Promise((resolve, reject) => {

        if (!replacedFilteredCampaignParticipants || !campaignWinners) {
            resolve(null);
            return;
        }

        const existingWinnersUsernames = new Set(campaignWinners.map(winner => winner.Username));

        // Filter participants to exclude the username being replaced and those who are already winners
        const eligibleParticipants = replacedFilteredCampaignParticipants.filter(
            participant => participant.Username !== username && !existingWinnersUsernames.has(participant.Username)
        );

        if (eligibleParticipants.length === 0) {
            // No eligible participants available
            resolve(null);
            return;
        }

        const randomIndex = Math.floor(Math.random() * eligibleParticipants.length);

        //override settings
        let isOverride: boolean = false;
        let filteredOverride: WinnerDTO[] = [];

        if (campaignStateDataDTO?.SocialPlatform === SocialPlatformEnum.Instagram &&
            campaignStateDataDTO?.CampaignType === CampaignTypeEnum.Comments &&
            campaignSettingsDTO.OverrideMostLikedComments) {
            filteredOverride = eligibleParticipants
                .filter(winner => winner.CommentLikeCount != null) // Filter out winners with null CommentLikeCount
                .sort((a, b) => (b.CommentLikeCount ?? 0) - (a.CommentLikeCount ?? 0))// Sort in descending order
                .slice(0, 1);

            isOverride = true;
        }

        if (isOverride) {
            resolve(filteredOverride.length > 0 ? filteredOverride[0] : eligibleParticipants[randomIndex]);

            return;
        }

        resolve(eligibleParticipants[randomIndex]);
    });
};

export const isValidInstagramUrl = (url: string): boolean => {
    try {
      const _url = new URL(url.trim());
    
      const regex = /^(https?:\/\/)?(www\.)?instagram\.com(\/.*)?$/;
  
      return regex.test(_url.toString());
    } catch (error) {
      return false;  // Invalid URL format
    }
  };
  
  export const isInstagramUsername = (username: string): boolean => {
    try {
      const normalizedUsername = username.trim().replace(/^@/, '');
  
      const regex = /^[a-zA-Z0-9_]{1,30}$/;
  
      return regex.test(normalizedUsername);
    }
    catch (error) {
      return false;
    }
  }
  
  export const getInstagramUsernameFormatted = (username: string): string => {
    return username.replace('@', '').trim();
  }
  
  export const getInstagramShortcodeFromPostOrReelUrl = (url: string): string | null => {
    const match = url.match(/\/(p|reel)\/([^\/?#]+)/);
    return match ? match[2] : null;
  }

  export const isValidTiktokUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(www\.)?tiktok\.com(\/.*)?$/;
    
    return regex.test(url);
}

export const isValidYoutubeUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)(\/.*)?$/;
    
    return regex.test(url);
}

export const isValidTwitterXUrl = (url: string): boolean => {
    const regex = /^(https?:\/\/)?(www\.)?x\.com(\/.*)?$/;
    
    return regex.test(url);
}