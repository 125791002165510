import React, { useEffect, useState } from 'react';
import loggerService from '../../services/LoggerService';
import { replaceRandomWinner } from '../../helpers/CampaignHelper';
import { getButtonErrorStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import NotificationService from '../../services/NotificationService';
import CloseButton from '../buttons/CloseButton';
import { useIsLoading } from '../../hooks/useIsLoading';
import CampaignLoading from '../loadings/CampaignLoading';
import { LoadingMessageEnum } from '../../helpers/LoadingMessageEnum';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { WinnerDTO } from '../../dto/WinnerDTO';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import { AxiosResponse } from 'axios';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/helpers/axiosInstance';
import { WinnerModel } from '../../models/WinnerModel';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { defaultMaxRetry, defaultWaitBetweenRequestsMs } from '../../config/envConfig';

interface CampaignWinnerReplaceModalProps {
    onClose: () => void;
    setWinnerToBeReplaced: (value: WinnerDTO | null) => void;
    winnerToBeReplaced: WinnerDTO | null;
}

const CampaignWinnerReplaceModal: React.FC<CampaignWinnerReplaceModalProps> = ({ onClose, setWinnerToBeReplaced, winnerToBeReplaced, }) => {
    const { campaignWinners, setCampaignWinners, filteredCampaignParticipants, setFilteredCampaignParticipants, campaignSettingsDTO, campaignStateDataDTO, replacementsCurrent, setReplacementsCurrent, campaignIdCurrent } = useCampaignContext();
    const notificationService = NotificationService();
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const [isUpdatingInvalid, setIsUpdatingInvalid] = useState<boolean>(false);
    const [invalidReason, setInvalidReason] = useState<string>("");

    useEffect(() => {
        if (isUpdatingInvalid) {
            const handleReplaceCall = async () => {
                await handleReplace();
            };

            handleReplaceCall();
        }

        return () => {
            setInvalidReason("");
        }
    }, [isUpdatingInvalid]);

    const handleReplace = async () => {
        const _return = () => {
            setIsUpdatingInvalid(false);
            setWinnerToBeReplaced(null);
            setIsLoading(false);
            onClose();
        }

        try {
            if (campaignWinners && filteredCampaignParticipants && campaignSettingsDTO && winnerToBeReplaced) {
                setIsLoading(true);
                setLoadingMessage("Replacing with a new winner...");

                if(replacementsCurrent > campaignSettingsDTO.ReplacementsMax)
                {
                    notificationService.setMessage("There are no replacements left", false);

                    _return();

                    return;
                }

                //we remove winnerUsernameToBeReplaced from the participant list so it cannot be picked again
                const replacedFilteredCampaignParticipants = filteredCampaignParticipants
                    .filter(participant =>
                        participant.Username !== winnerToBeReplaced.Username
                    );

                //remove duplicates for based on username, so we can have an accurate count
                const seenUsernames = new Set();
                const countFilteredCampaignParticipants = replacedFilteredCampaignParticipants?.filter((winner) => {
                    if (seenUsernames.has(winner.Username)) {
                        return false;
                    } else {
                        seenUsernames.add(winner.Username);
                        return true;
                    }
                });

                if (countFilteredCampaignParticipants.length < campaignSettingsDTO?.Winners) {
                    notificationService.setMessage("There are no participants left to replace", false);

                    _return();

                    return;
                }

                setFilteredCampaignParticipants(replacedFilteredCampaignParticipants);

                const newWinner: WinnerDTO | null = await replaceRandomWinner(campaignStateDataDTO, campaignSettingsDTO, winnerToBeReplaced.Username, campaignWinners, replacedFilteredCampaignParticipants);

                if (newWinner) {
                    setCampaignWinners(prevWinners => {
                        if (prevWinners === null) {
                            return [newWinner];
                        }

                        const updatedWinners = prevWinners
                            .map(winner =>
                                winner.Username === winnerToBeReplaced.Username
                                    ? { ...winner, IsValid: false, InvalidReason: invalidReason }
                                    : winner
                            )
                            .concat(newWinner);

                        return updatedWinners;
                    });

                    winnerToBeReplaced.IsValid = false;
                    winnerToBeReplaced.InvalidReason = invalidReason;
                    const formData = {
                        campaignId: campaignIdCurrent,
                        newWinner: newWinner,
                        replaceWinner: winnerToBeReplaced
                    }

                    const replaceCampaignWinner = async (retries = defaultMaxRetry, delay = defaultWaitBetweenRequestsMs): Promise<AxiosResponse> => {
                        try {
                            await new Promise(resolve => setTimeout(resolve, 6000));
                            const response = await axiosInstance.post(
                                ApiRoutesEnum.REPLACE_CAMPAIGN_WINNER,
                                formData,
                                { authNeeded: true } as CustomAxiosRequestConfig);
                            return response;
                        } catch (error) {
                            if (retries > 0) {
                                await new Promise(resolve => setTimeout(resolve, delay));

                                return replaceCampaignWinner(retries - 1, delay);
                            }
                            else {
                                throw error;
                            }
                        }
                    };

                    await replaceCampaignWinner();

                    setReplacementsCurrent(replacementsCurrent + 1);

                    _return();

                    return;
                }
                else {
                    notificationService.setMessage("There are no participants left to replace", false);

                    _return();

                    return;
                }
            }
        }
        catch (error: any) {
            loggerService.error(error);

            notificationService.setMessage(`Failed to invalidate participant ${winnerToBeReplaced?.UsernameDisplay}`, false);

            _return();
        }
    };

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
                <div className="relative bg-white p-6 rounded shadow-lg w-full max-w-lg mx-4 sm:mx-0 max-h-[90vh] overflow-y-auto">
                    {!isLoading && (
                        <div className='flex items-center justify-center absolute top-2 right-2'>
                            <CloseButton
                                text="Close"
                                onClick={onClose}
                            />
                        </div>
                    )}

                    <div className="flex flex-col items-center">
                        <p className="mb-4 mt-4 text-center">
                            {campaignSettingsDTO?.ReplacementsMax ? campaignSettingsDTO.ReplacementsMax - replacementsCurrent : 0} / {campaignSettingsDTO?.ReplacementsMax} Replacements available
                        </p>
                        <p className="mb-4 mt-4 text-center">
                            Are you sure you want to invalidate this winner <span className='text-lg font-bold'>{winnerToBeReplaced?.UsernameDisplay}</span> and shuffle another participant ?
                        </p>
                        <div className="mb-4 mt-4 text-center w-full">
                            <input type="text" className={`${getInputTextStyleClass()} w-full p-2 mt-1`}
                                onChange={(e) => setInvalidReason(e.target.value)}
                                maxLength={50}
                                placeholder='Invalid reason...'
                                disabled={isLoading}
                            />
                        </div>
                        <div className="px-5 mt-4 text-center">
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-2">
                                <button onClick={() => setIsUpdatingInvalid(true)} 
                                disabled={isLoading}
                                className={`${getButtonErrorStyleClass(false)} py-2 px-4`}>
                                    {isLoading ? "Invalidating, wait..." : "Invalidate"}
                                </button>
                                <CloseButton
                                    text='Cancel'
                                    onClick={onClose}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default CampaignWinnerReplaceModal;