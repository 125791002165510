import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactPaginate from 'react-paginate';

interface SPPaginationProps {
  pageCount: number;
  onPageChange: (selected: { selected: number }) => void;
  currentPage: number;
}

const SPPagination: React.FC<SPPaginationProps> = ({ pageCount, onPageChange, currentPage }) => {

  var normalizedPage = Math.max(0, Math.min(currentPage - 1, pageCount - 1));

  return (
    <div className="flex items-center justify-center mt-4">
      <ReactPaginate
        pageCount={pageCount === 0 ? 1 : pageCount}
        onPageChange={onPageChange}
        forcePage={normalizedPage}
        containerClassName="flex items-center space-x-2"
        pageClassName="cursor-pointer"
        pageLinkClassName="px-4 py-2 rounded-lg hover:text-secondary"
        previousLabel={<FontAwesomeIcon className='hover:text-secondary' icon={faArrowLeft} />}
        nextLabel={<FontAwesomeIcon className='hover:text-secondary' icon={faArrowRight} />}
        previousClassName="cursor-pointer px-4 py-2 rounded-lg"
        nextClassName="cursor-pointer px-4 py-2 rounded-lg"
        breakLabel="..."
        breakClassName="px-4 py-2"
        activeClassName="font-semibold text-lg text-primary hover:text-secondary"
        disabledClassName="opacity-50 cursor-not-allowed"
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
      />
    </div>
  );
};

export default SPPagination;