import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { useAuthContext } from './../contexts/AuthContext';
import LoadingScreen from '../../components/loadings/LoadingScreen';
import { useIsLoading } from '../../hooks/useIsLoading';

interface RoleProtectedRouteProps {
  roles: string[];
}

const RoleProtectedRoute: React.FC<RoleProtectedRouteProps> = ({ roles }) => {
  const { logout, isLoggedIn, hasRole } = useAuthContext();
  const [hasRequiredRole, setHasRequiredRole] = useState<boolean>(false);
  const { isLoading, setIsLoading } = useIsLoading(true);

  useEffect(() => {
    const checkIsLoggedIn = async () => {

      const _isLoggedIn = await isLoggedIn();

      if (!_isLoggedIn) {
        await logout();

        setIsLoading(false);
      }
    };

    const checkRole = async () => {
      const _hasRole = await Promise.all(roles.map(role => hasRole(role)));
      const anyRole = _hasRole.some(role => role);

      setHasRequiredRole(anyRole);

      setIsLoading(false);
    };

    checkIsLoggedIn();
    checkRole();
  }, [roles]);

  if (isLoading)
    return <LoadingScreen />;
  else
    return hasRequiredRole ? <Outlet /> : <Navigate to={AppRoutes.DASHBOARD} />;
};

export default RoleProtectedRoute;