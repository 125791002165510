import React, { useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import NotificationService from '../services/NotificationService';
import { ApiRoutesEnum } from '../routes/ApiRoutesEnum';
import { getBackgroundActive, getButtonSuccessStyleClass } from '../config/FormSettings';
import loggerService from '../services/LoggerService';
import { participantCreditPackages } from '../config/envConfig';
import { prettifyNumbers } from '../helpers/StringsHelper';
import useIsMobile from '../hooks/useIsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faListNumeric, faX } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const Checkout: React.FC = () => {
  const notification = NotificationService();
  const { isMobile } = useIsMobile();
  const [selectedPackage, setSelectedPackage] = useState(participantCreditPackages[0]);
  const selectedPackageRef = useRef<HTMLDivElement | null>(null);

  const handleCheckout = async () => {
    try {
      const stripe = await stripePromise;

      if (!stripe)
        throw new Error("Failed to load stripePromise");

      const response = await fetch(ApiRoutesEnum.CREATE_CHECKOUT_SESSION, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...selectedPackage }),
      });
      const { sessionId } = await response.json();

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        throw error;
      }
    } catch (error) {
      loggerService.error(error);

      notification.setMessage("Failed to create payment", false);
    }
  };

  const handlePackageClick = (pkg: typeof participantCreditPackages[0]) => {
    setSelectedPackage(pkg);

    if (isMobile) {
      selectedPackageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="container mx-auto p-4">

      <h2 className="text-xl font-bold mb-4">Choose Your Package</h2>
      <div className="text-gray-800 text-sm pb-2 pt-1 font-semibold">
        1 participant credit = 1 comment or retweet.
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 pr-0 md:pr-4 mb-4 md:mb-0">
          <div className="flex flex-wrap gap-4">
            {participantCreditPackages.map((pkg) => (
              <button
                key={pkg.ParticipantCredits}
                onClick={() => handlePackageClick(pkg)}
                className={`p-2 m-2 rounded-lg shadow-lg flex-col w-full md:w-[calc(45%-1rem)] min-h-[90px] items-center justify-between hover:bg-gray-100
                ${selectedPackage.ParticipantCredits === pkg.ParticipantCredits ? `${getBackgroundActive()} shadow-primary` : 'bg-white text-gray-800 border border-gray-300'}`}
              >
                <div className="text-base">
                  <span className={`text-md font-semibold
                    ${selectedPackage.ParticipantCredits === pkg.ParticipantCredits ? `text-white` : ''}
                    `}>{prettifyNumbers(pkg.ParticipantCredits)}</span> participant credits
                </div>
                <div className={`text-xl font-bold
                  ${selectedPackage.ParticipantCredits === pkg.ParticipantCredits ? `text-white` : 'text-success'}`}>
                  <span className='text-sm'>$</span> {pkg.Price.toFixed(2)}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="w-full md:w-1/2" ref={selectedPackageRef}>
          <div className="p-4 bg-successBackground rounded-lg shadow-lg transition-transform duration-500 transform">
            <h3 className="text-xl font-bold mb-2">Selected Package</h3>
            <div className="text-1xl pt-2 font-semibold transition-colors duration-500 text-success">
              {prettifyNumbers(selectedPackage.ParticipantCredits)} participant credits
            </div>
            <div className="text-gray-800 text-sm pb-2 pt-1 font-semibold">
              1 participant credit = 1 comment or retweet.
            </div>
            <div className="text-gray-800 text-sm">
              You can create unlimited giveaways based on your Participant Credits, for each giveaway the Participant Credits will be deducted from your account based on the number of comments, retweets, that giveaway has
            </div>
            <div className="text-gray-800 text-sm pt-4">
              Only
            </div>
            <div className="text-[30px] pb-2 font-semibold transition-colors duration-500 text-success">
              <span className='text-[20px]'>$</span> {selectedPackage.Price.toFixed(2)}
            </div>
            <div className="p-2 w-full">
              <button className={`${getButtonSuccessStyleClass(true)} w-full`} onClick={handleCheckout}>
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto shadow-md mt-4 mb-4 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto">
          <div className="flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-semibold leading-6 text-gray-800">What’s included</h4>
            <div className="h-px flex-auto bg-gray-100"></div>
          </div>
          <ul className="mt-8 grid grid-cols-1 gap-4 text-sm items-center leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faInstagram} className='text-instagram' />
              Up to 10,000 Instagram Comments per Giveaway
            </li>
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faTiktok} className='text-tiktok' />
              Up to 30,000 TikTok Comments per Giveaway
            </li>
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faX} className='text-twitter' />
              Up to 30,000 Twitter / X Retweeters per Giveaway
            </li>   
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faYoutube} className='text-youtube' />
              Up to 10,000 Youtube Comments per Giveaway
            </li>       
            <li className="flex gap-x-3 items-center">
              <FontAwesomeIcon icon={faCheck} className='text-success mr-2' />
              <FontAwesomeIcon icon={faListNumeric} className='text-randomNameWinner' />
              Unlimited Random Name Winner List Participants per Giveaway
            </li>    
          </ul>
        </div>
      </div>

    </div>
  );
};

export default Checkout;