import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faExternalLink, faQuestion, faRemove } from '@fortawesome/free-solid-svg-icons';
import CampaignCountdown from './CampaignCountdown';
import { Link, useNavigate } from 'react-router-dom';
import CampaignWinnerReplaceModal from './CampaignWinnerReplaceModal';
import { getButtonErrorStyleClass, getButtonSuccessStyleClass, getTextLinkStyleClass } from '../../config/FormSettings';
import ConfettiCanvas from '../ConfettiCanvas';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { useIsLoading } from '../../hooks/useIsLoading';
import CampaignLoading from '../loadings/CampaignLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { prettifyNumbers } from '../../helpers/StringsHelper';
import { RoutesEnum } from '../../routes/RoutesEnum';
import { WinnerDTO } from '../../dto/WinnerDTO';

const CampaignWinner: React.FC = () => {
    const { campaignWinners } = useCampaignContext();
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const navigate = useNavigate();

    const [isOpenCampaignWinnerReplaceModal, setIsOpenCampaignWinnerReplaceModal] = useState<boolean>(false);
    const [winnerToBeReplaced, setWinnerToBeReplaced] = useState<WinnerDTO | null>();
    const [isCountdown, setIsCountdown] = useState<boolean>(true);

    const handleReplace = async (winner: WinnerDTO) => {
        setWinnerToBeReplaced(winner);
        setIsOpenCampaignWinnerReplaceModal(true);
    };

    const shareCampaign = () => {
        navigate(RoutesEnum.DASHBOARD);
    }

    return (
        <>
            {isLoading ? (
                <>
                    <CampaignLoading
                        loadingMessage={loadingMessage}
                    />
                </>
            ) : (
                <>
                    {isCountdown ?
                        (
                            <>
                                <CampaignCountdown
                                    setIsCountdown={setIsCountdown}
                                    isCountdown={isCountdown}
                                />
                            </>
                        ) :
                        (
                            <>
                                <div className="text-center mb-4">
                                    <h4 className="text-xl text-gray-400 font-semibold">Winners</h4>
                                </div>
                                <div className="p-4 gap-2 justify-center items-center">

                                    {campaignWinners && campaignWinners.map((winner, index) => (
                                        <div key={winner.Username} className={`relative bg-white rounded-lg px-4 py-2 m-4 flex items-center shadow-lg border-[0.5px] 
                                        ${winner.IsValid ?
                                                "border-success shadow-success"
                                                :
                                                "border-error shadow-error"
                                            }`}>
                                            <div className="flex-1 w-full">

                                                <div className="absolute left-[20px] text-[40px] font-bold text-gray-200 font-semibold text-center items-center">
                                                    {index + 1}
                                                </div>
                                                <>
                                                    {winner.ProfileImage && (
                                                        <div className="text-center mt-4">
                                                            <img
                                                                alt={winner.UsernameDisplay}
                                                                src={winner.ProfileImage}
                                                                className="rounded-full w-12 h-full mx-auto"
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                                <div className="text-lg font-semibold text-center items-center p-2">
                                                    <FontAwesomeIcon icon={winner.SocialPlatformIcon ?? faQuestion} className={`mr-2 ${winner.SocialPlatformClassName}`} />
                                                    {winner?.UsernameDisplay}
                                                </div>

                                                {!winner.IsValid && winner?.InvalidReason && (
                                                    <div className="bg-gray-100 shadow-md text-center text-sm text-gray-600 mt-2 p-4">
                                                        <div className="flex justify-between items-center">
                                                            <div className="items-center flex-1">
                                                                <FontAwesomeIcon icon={faRemove} className={`mr-2 text-error`} />
                                                                {winner?.InvalidReason}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {winner?.Comment || winner?.CommentLikeCount != null && (
                                                    <div className="bg-gray-100 shadow-md text-center text-sm text-gray-600 mt-2 p-4">
                                                        <div className="flex justify-between items-center">

                                                            {/*left side*/}
                                                            <div className="flex items-center flex-1">
                                                                <FontAwesomeIcon icon={winner.CommentsSocialIcon ?? faQuestion} className={`mr-2 ${winner.SocialPlatformClassName}`} />
                                                                {winner?.Comment}
                                                            </div>
                                                            {/*left side - end*/}
                                                            {/*right side*/}
                                                            {winner?.CommentLikeCount != null && (
                                                                <div className="flex items-center">
                                                                    <div className="text-right text-sm ml-2 mr-2">
                                                                        {prettifyNumbers(winner?.CommentLikeCount ?? null)}
                                                                    </div>
                                                                    <div className="text-right">
                                                                        <FontAwesomeIcon icon={winner.SocialPlatformLikeIcon ?? faQuestion} className={`mr-2 ${winner.SocialPlatformClassName}`} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {/*right side - end*/}

                                                        </div>
                                                    </div>
                                                )}

                                                {/*buttons*/}
                                                <div className="bg-gray-100 shadow-md text-center items-center text-sm text-gray-600 mt-2 p-2">
                                                    <div className={`flex flex-col sm:flex-row gap-2 justify-center items-center`}>
                                                        {winner?.ProfileLink && (
                                                            <>
                                                                <Link to={winner.ProfileLink} target="_blank" className={`${getTextLinkStyleClass()} py-2 px-4 rounded`}>
                                                                    Profile
                                                                    <FontAwesomeIcon icon={faExternalLink} className='ml-1 text-black' />
                                                                </Link>
                                                            </>
                                                        )}
                                                        {winner?.CommentLink && (
                                                            <>
                                                                <Link to={winner.CommentLink} target="_blank" className={`${getTextLinkStyleClass()} py-2 px-4 rounded`}>
                                                                    Comment
                                                                    <FontAwesomeIcon icon={faExternalLink} className='ml-1 text-black' />
                                                                </Link>
                                                            </>
                                                        )}
                                                        {winner.IsValid && (
                                                            <button onClick={() => handleReplace(winner)} className={`${getButtonErrorStyleClass(false)} py-2 px-4`}>
                                                                Invalidate
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                {/*buttons - end*/}

                                            </div>
                                        </div>
                                    ))}

                                </div>

                                <ConfettiCanvas />

                                <div className="fixed bottom-0 left-0 right-0 flex justify-center mb-4">
                                    <button
                                        type="button" onClick={shareCampaign} className={`${getButtonSuccessStyleClass(true)} w-full max-w-md`}>
                                        Share
                                    </button>
                                </div>
                            </>
                        )}

                    {isOpenCampaignWinnerReplaceModal && winnerToBeReplaced && (
                        <CampaignWinnerReplaceModal
                            onClose={() => setIsOpenCampaignWinnerReplaceModal(false)}
                            setWinnerToBeReplaced={setWinnerToBeReplaced}
                            winnerToBeReplaced={winnerToBeReplaced}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default CampaignWinner;
