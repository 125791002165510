export enum ApiRoutesEnum {
  GET_USERS = '/api/Users/get-users',
  GET_USER = '/api/Users/get-user',
  SIGNUP = '/api/Users/signup',
  LOGIN = '/api/Users/login',
  REFRESH = '/api/Users/refresh',
  RESET_PASSWORD = '/api/Users/reset-password',
  SET_NEW_PASSWORD = '/api/Users/set-new-password',
  EMAIL_VERIFY = '/api/Users/email-verify',
  SET_EMAIL_VERIFY = '/api/Users/set-email-verify',
  CHECK_EMAIL_VERIFY = '/api/Users/check-email-verify',
  OAUTH_GOOGLE = '/api/Users/oauth-google',
  OAUTH_FACEBOOK_INSTAGRAM = '/api/Users/oauth-facebook-instagram',

  GET_CAMPAIGNS_STATISTICS = '/api/Campaigns/get-campaigns-statistics',
  GET_CAMPAIGNS_BY_USER = '/api/Campaigns/get-campaigns-by-user',
  CREATE_CAMPAIGN = '/api/Campaigns/create-campaign',
  REPLACE_CAMPAIGN_WINNER = '/api/Campaigns/replace-campaign-winner',

  CAMPAIGNS_FETCH_GET_POST = '/api/CampaignsFetch/get-post',
  CAMPAIGNS_FETCH_GET_COMMENTS = '/api/CampaignsFetch/get-comments',

  CREATE_CHECKOUT_SESSION = '/api/Payment/create-checkout-session'
}