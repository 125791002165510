import React from 'react';
import Layout from '../../../components/Layout';
import { Helmet } from 'react-helmet';
import InstagramUrlApp from '../../../components/campaign/apps/InstagramUrlApp';

const InstagramUrl: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Instagram Giveaway Comment Picker</title>
      </Helmet>
      <InstagramUrlApp />
    </Layout>
  );
};

export default InstagramUrl;