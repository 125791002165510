import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faDice, faRandom, faCoins, faUser, faCreditCard, faSignOutAlt, faArrowAltCircleUp, faThLarge, faUserCircle, faUserShield, faQuestion, faListDots, faX, faSearch, faListNumeric, faImagePortrait, faImage, faGrip } from '@fortawesome/free-solid-svg-icons';
import useIsMobile from '../hooks/useIsMobile';
import { RoutesEnum as AppRoutes } from '../routes/RoutesEnum';
import { RolesEnum as Roles } from '../auth/helpers/RolesEnum';
import { useAuthContext } from '../auth/contexts/AuthContext';
import { isRouteActive } from '../helpers/RoutesHelper';
import { getBackgroundActive, getBackgroundHover, getButtonPrimaryStyleClass, getButtonSuccessStyleClass } from '../config/FormSettings';

const Navigation: React.FC = () => {
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const { userData, logout, isLoggedInState } = useAuthContext();
  const desktopAppsButtonRef = useRef<HTMLButtonElement>(null);
  const desktopAppsDropdownRef = useRef<HTMLDivElement>(null);
  const mobileAppsButtonRef = useRef<HTMLButtonElement>(null);
  const mobileAppsDropdownRef = useRef<HTMLDivElement>(null);
  const desktopDashboardButtonRef = useRef<HTMLButtonElement>(null);
  const desktopDashboardDropdownRef = useRef<HTMLDivElement>(null);
  const mobileDashboardButtonRef = useRef<HTMLButtonElement>(null);
  const mobileDashboardDropdownRef = useRef<HTMLDivElement>(null);
  const [maxHeightUserMenuMobile] = useState('calc(100vh - 93px)');
  const [maxHeightAppsMenuMobile] = useState('calc(100vh - 65px)');
  const [isOpenDashboardMenu, setIsOpenDashboardMenu] = useState(false);
  const [isOpenAppsMenu, setIsOpenAppsMenu] = useState(false);

  useEffect(() => {
    document.removeEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile]);

  const handleClickOutside = (event: MouseEvent) => {
    if (!isMobile) {
      if (
        desktopAppsDropdownRef.current &&
        !desktopAppsDropdownRef.current.contains(event.target as Node) &&
        desktopAppsButtonRef.current &&
        !desktopAppsButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenAppsMenu(false);
      }

      if (
        desktopDashboardDropdownRef.current &&
        !desktopDashboardDropdownRef.current.contains(event.target as Node) &&
        desktopDashboardButtonRef.current &&
        !desktopDashboardButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenDashboardMenu(false);
      }
    }
    else {
      if (
        mobileAppsDropdownRef.current &&
        !mobileAppsDropdownRef.current.contains(event.target as Node) &&
        mobileAppsButtonRef.current &&
        !mobileAppsButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenAppsMenu(false);
      }

      if (
        mobileDashboardDropdownRef.current &&
        !mobileDashboardDropdownRef.current.contains(event.target as Node) &&
        mobileDashboardButtonRef.current &&
        !mobileDashboardButtonRef.current.contains(event.target as Node)
      ) {
        setIsOpenDashboardMenu(false);
      }
    }
  };

  const toggleDashboardMenu = () => {
    setIsOpenDashboardMenu(!isOpenDashboardMenu);
    if (!isOpenDashboardMenu) {
      setIsOpenAppsMenu(false);
    }
  };

  const toggleAppsMenu = () => {
    setIsOpenAppsMenu(!isOpenAppsMenu);
    if (!isOpenAppsMenu) {
      setIsOpenDashboardMenu(false);
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <nav className="bg-white p-2 text-black fixed top-0 left-0 right-0 border-b border-gray-300 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <Link to={AppRoutes.HOME} className="flex items-center">
          <img src={isMobile || isLoggedInState ? "/assets/logoIconBlack.svg" : "/assets/logoBlack.svg"} alt="Logo" className={isMobile || isLoggedInState ? "w-full h-auto max-w-01xs" : "w-full h-auto max-w-3xs"} />
        </Link>
        {/*start desktop menu*/}
        <div className="hidden md:flex space-x-4 items-center">
          {isLoggedInState && (
            <Link to={AppRoutes.DASHBOARD} className={`hover:text-secondary 
              ${isRouteActive(location, AppRoutes.DASHBOARD) ?
                'text-secondary' :
                ''}`}>
              My Campaigns
            </Link>
          )}
          <div className="relative">
            <button ref={desktopAppsButtonRef} onClick={toggleAppsMenu} className={`flex items-center group hover:text-secondary ${isOpenAppsMenu && "text-secondary"}`}>
              <FontAwesomeIcon icon={faGrip} className={`${isOpenAppsMenu ? "text-secondary" : "text-primary"} mr-2 group-hover:text-secondary`} />
              Apps
              <svg
                className={`ml-2 h-4 w-4 transition-transform ${isOpenAppsMenu ? 'rotate-180' : 'rotate-0'}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {isOpenAppsMenu && (
              <div ref={desktopAppsDropdownRef} className="fixed left-0 w-screen bg-white border border-gray-300 rounded shadow-lg z-50 overflow-y-auto" style={{ top: '57px', maxHeight: maxHeightAppsMenuMobile }}>
                <div className="container mx-auto py-4">
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                    <div>
                      <div className="mb-2 text-gray-500 px-4">Giveaways / Promotions</div>
                      <Link to={AppRoutes.APP_INSTAGRAM_URL} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_INSTAGRAM_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faInstagram} className={`mr-2 text-instagram group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_INSTAGRAM_URL) && "text-white"}
                          `} />
                        Instagram Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_TIKTOK_URL} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_TIKTOK_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faTiktok} className={`mr-2 text-tiktok group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_TIKTOK_URL) && "text-white"}
                          `} />
                        TikTok Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_YOUTUBE_URL} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_YOUTUBE_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faYoutube} className={`mr-2 group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_YOUTUBE_URL) ? "text-white" : "text-youtube"}
                          `} />
                        Youtube Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_TWITTER_URL} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_TWITTER_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faX} className={`mr-2 text-twitter group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_TWITTER_URL) && "text-white"}
                          `} />
                        Twitter / X Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_RANDOM_NAME_WINNERS} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_RANDOM_NAME_WINNERS) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faListNumeric} className={`mr-2 text-randomNameWinner group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_RANDOM_NAME_WINNERS) && "text-white"}
                          `} />
                        Random Name Winner Giveaway
                      </Link>
                    </div>
                    <div>
                      <div className="mb-2 text-gray-500 px-4">Tools</div>
                      <Link to={AppRoutes.CAMPAIGN_SEARCH_BY_CODE} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.CAMPAIGN_SEARCH_BY_CODE) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faSearch} className={`mr-2 text-secondary group-hover:text-white
                          ${isRouteActive(location, AppRoutes.CAMPAIGN_SEARCH_BY_CODE) && "text-white"}
                          `} />
                        Search Giveaway by Code
                      </Link>
                      <Link to={AppRoutes.APP_CREATE_GIVEAWAY_IMAGE} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_CREATE_GIVEAWAY_IMAGE) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faImage} className={`mr-2 text-numbers group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_CREATE_GIVEAWAY_IMAGE) && "text-white"}
                          `} />
                        Create Giveaway Image
                      </Link>
                    </div>
                    <div>
                      <div className="mb-2 text-gray-500 px-4">Random</div>
                      <Link to={AppRoutes.APP_ROLL_DICE} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_ROLL_DICE) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faDice} className={`mr-2 text-secondary group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_ROLL_DICE) && "text-white"}
                          `} />
                        Roll Dice
                      </Link>
                      <Link to={AppRoutes.APP_RANDOM_NUMBERS} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_RANDOM_NUMBERS) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faRandom} className={`mr-2 text-numbers group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_RANDOM_NUMBERS) && "text-white"}
                          `} />
                        Random Numbers
                      </Link>
                      <Link to={AppRoutes.APP_FLIP_COIN} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_FLIP_COIN) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faCoins} className={`mr-2 text-coins group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_FLIP_COIN) && "text-white"}
                          `} />
                        Flip a Coin
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isLoggedInState && (
            <Link to={AppRoutes.PLANS} className={`hover:text-secondary 
            ${isRouteActive(location, AppRoutes.PLANS) ?
                'text-secondary' :
                ''}`}>
              Prices
            </Link>
          )}
          <Link to={AppRoutes.HELP} className={`hover:text-secondary 
            ${isRouteActive(location, AppRoutes.HELP) ?
              'text-secondary'
              : ''}`}>
            Help
          </Link>
        </div>
        <div className="hidden md:flex space-x-4 items-center">
          {isLoggedInState ? (
            <>
              <Link to={AppRoutes.PLANS} className={`${getButtonSuccessStyleClass(false)} block px-4 py-2`}>
                <FontAwesomeIcon icon={faArrowAltCircleUp} className="mr-2 text-white" />
                Upgrade
              </Link>
              <div className="relative hl">
                <button ref={desktopDashboardButtonRef} onClick={toggleDashboardMenu} className={`flex items-center group hover:text-secondary ${isOpenDashboardMenu && "text-secondary"}`}>
                  <FontAwesomeIcon icon={faUserCircle} className={`mr-2 ${isOpenDashboardMenu ? 'text-secondary' : 'text-primary'} group-hover:text-secondary`} size="2x" />
                  <svg
                    className={`ml-2 h-4 w-4 transition-transform ${isOpenDashboardMenu ? 'rotate-180' : 'rotate-0'} group-hover:text-secondary`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {isOpenDashboardMenu && (
                  <div ref={desktopDashboardDropdownRef} className="absolute right-0 mt-2 w-56 bg-white border border-gray-300 rounded shadow-lg z-10 overflow-y-auto max-h-[75vh]">
                    <div className="py-1">
                      {userData?.Role === Roles.ADMIN && (
                        <Link to={AppRoutes.ADMIN_DASHBOARD} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                        ${isRouteActive(location, AppRoutes.ADMIN_DASHBOARD) ?
                            getBackgroundActive() :
                            ''}`}>
                          <FontAwesomeIcon icon={faUserShield} className={`mr-2 
                            ${isRouteActive(location, AppRoutes.ADMIN_DASHBOARD) ?
                              'text-white' :
                              'text-primary group-hover:text-white'} `} />
                          Admin Dashboard
                        </Link>
                      )}
                      <Link to={AppRoutes.DASHBOARD} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.DASHBOARD) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faListDots} className={`mr-2 
                          ${isRouteActive(location, AppRoutes.DASHBOARD) ?
                            'text-white' :
                            'text-primary group-hover:text-white'} `} />
                        My Campaigns
                      </Link>
                      <Link to={AppRoutes.ACCOUNT} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.ACCOUNT) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faUser} className={`mr-2 
                          ${isRouteActive(location, AppRoutes.ACCOUNT) ?
                            'text-white' :
                            'text-primary group-hover:text-white'} `} />
                        Account
                      </Link>
                      <Link to={AppRoutes.BILLING} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.BILLING) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faCreditCard} className={`mr-2 
                          ${isRouteActive(location, AppRoutes.BILLING) ?
                            'text-white' :
                            'text-primary group-hover:text-white'} `} />
                        Billing
                      </Link>
                      <Link to={AppRoutes.HELP} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.HELP) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faQuestion} className={`mr-2 
                          ${isRouteActive(location, AppRoutes.HELP) ?
                            'text-white' :
                            'text-primary group-hover:text-white'} `} />
                        Help
                      </Link>
                      <div className="border-t border-gray-200"></div>
                      <button onClick={handleLogout} className={`w-full text-left px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()}`}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 text-primary group-hover:text-white" />
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Link to={AppRoutes.LOGIN} className="hover:text-secondary">
                Login
              </Link>
              <Link to={AppRoutes.SIGNUP} className={`${getButtonPrimaryStyleClass(false)} px-4 py-2`}>
                Signup
              </Link>
            </>
          )}
        </div>
        {/*end desktop menu*/}
        {/*start mobile menu*/}
        <div className="md:hidden flex items-center">
          <div className="relative">
            <button ref={mobileAppsButtonRef} onClick={toggleAppsMenu} className={`flex items-center group hover:text-secondary ${isOpenAppsMenu && "text-secondary"}`}>
              <FontAwesomeIcon icon={faThLarge} className={`${isOpenAppsMenu ? "text-secondary" : "text-primary"} mr-2 group-hover:text-secondary`} />
              Apps
              <svg
                className={`ml-2 h-4 w-4 transition-transform ${isOpenAppsMenu ? 'rotate-180' : 'rotate-0'}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {isOpenAppsMenu && (
              <div ref={mobileAppsDropdownRef} className="fixed left-0 w-screen bg-white border border-gray-300 rounded shadow-lg z-50 overflow-y-auto" style={{ top: '58px', maxHeight: maxHeightAppsMenuMobile }}>
                <div className="container mx-auto py-4">
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <div className="mb-2 text-gray-500 px-4">Giveaways</div>
                      <Link to={AppRoutes.APP_INSTAGRAM_URL} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_INSTAGRAM_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faInstagram} className={`mr-2 text-instagram group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_INSTAGRAM_URL) && "text-white"}
                          `} />
                        Instagram Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_TIKTOK_URL} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_TIKTOK_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faTiktok} className={`mr-2 text-tiktok group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_TIKTOK_URL) && "text-white"}
                          `} />
                        TikTok Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_YOUTUBE_URL} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_YOUTUBE_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faYoutube} className={`mr-2 group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_YOUTUBE_URL) ? "text-white" : "text-youtube"}
                          `} />
                        Youtube Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_TWITTER_URL} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_TWITTER_URL) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faX} className={`mr-2 text-twitter group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_TWITTER_URL) && "text-white"}
                          `} />
                        Twitter / X Giveaway
                      </Link>
                      <Link to={AppRoutes.APP_RANDOM_NAME_WINNERS} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_RANDOM_NAME_WINNERS) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faListNumeric} className={`mr-2 text-randomNameWinner group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_RANDOM_NAME_WINNERS) && "text-white"}
                          `} />
                        Random Name Winner Giveaway
                      </Link>
                    </div>
                    <div>
                      <div className="mb-2 text-gray-500 px-4">Tools</div>
                      <Link to={AppRoutes.CAMPAIGN_SEARCH_BY_CODE} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.CAMPAIGN_SEARCH_BY_CODE) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faSearch} className={`mr-2 text-secondary group-hover:text-white
                          ${isRouteActive(location, AppRoutes.CAMPAIGN_SEARCH_BY_CODE) && "text-white"}
                          `} />
                        Search Giveaway by Code
                      </Link>
                      <Link to={AppRoutes.APP_CREATE_GIVEAWAY_IMAGE} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_CREATE_GIVEAWAY_IMAGE) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faImage} className={`mr-2 text-numbers group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_CREATE_GIVEAWAY_IMAGE) && "text-white"}
                          `} />
                        Create Giveaway Image
                      </Link>
                    </div>
                    <div>
                      <div className="mb-2 text-gray-500 px-4">Random</div>
                      <Link to={AppRoutes.APP_ROLL_DICE} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_ROLL_DICE) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faDice} className={`mr-2 text-secondary group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_ROLL_DICE) && "text-white"}
                          `} />
                        Roll Dice
                      </Link>
                      <Link to={AppRoutes.APP_RANDOM_NUMBERS} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_RANDOM_NUMBERS) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faRandom} className={`mr-2 text-numbers group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_RANDOM_NUMBERS) && "text-white"}
                          `} />
                        Random Numbers
                      </Link>
                      <Link to={AppRoutes.APP_FLIP_COIN} className={`block pl-2 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                      ${isRouteActive(location, AppRoutes.APP_FLIP_COIN) ?
                          getBackgroundActive() :
                          ''}`}>
                        <FontAwesomeIcon icon={faCoins} className={`mr-2 text-coins group-hover:text-white
                          ${isRouteActive(location, AppRoutes.APP_FLIP_COIN) && "text-white"}
                          `} />
                        Flip a Coin
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="md:hidden flex items-center">
          <button ref={mobileDashboardButtonRef} onClick={toggleDashboardMenu}>
            {isOpenDashboardMenu ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3Icon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      {isOpenDashboardMenu && (
        <div ref={mobileDashboardDropdownRef} className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 overflow-y-auto" style={{ maxHeight: maxHeightUserMenuMobile }}>
            {isLoggedInState ? (
              <>
                {userData?.Role === Roles.ADMIN && (
                  <Link to={AppRoutes.ADMIN_DASHBOARD} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                  ${isRouteActive(location, AppRoutes.ADMIN_DASHBOARD) ?
                      'bg-secondary text-white' :
                      ''}`}>
                    <FontAwesomeIcon icon={faUserShield} className={`mr-2 
                      ${isRouteActive(location, AppRoutes.ADMIN_DASHBOARD) ?
                        'text-white' :
                        'text-primary group-hover:text-white'} `} />
                    Admin Dashboard
                  </Link>
                )}
                <Link to={AppRoutes.PLANS} className={`${getButtonSuccessStyleClass(false)} block px-4 py-2`}>
                  <FontAwesomeIcon icon={faArrowAltCircleUp} className="mr-2 text-white" />
                  Upgrade
                </Link>
                <Link to={AppRoutes.DASHBOARD} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                ${isRouteActive(location, AppRoutes.DASHBOARD) ?
                    getBackgroundActive() :
                    ''}`}>
                  <FontAwesomeIcon icon={faListDots} className={`mr-2 
                    ${isRouteActive(location, AppRoutes.DASHBOARD) ?
                      'text-white' :
                      'text-primary group-hover:text-white'} `} />
                  My Campaigns
                </Link>
                <Link to={AppRoutes.ACCOUNT} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()}
                ${isRouteActive(location, AppRoutes.ACCOUNT) ?
                    getBackgroundActive()
                    : ''}`}>
                  <FontAwesomeIcon icon={faUser} className={`mr-2 
                    ${isRouteActive(location, AppRoutes.ACCOUNT) ?
                      'text-white' :
                      'text-primary group-hover:text-white'} `} />
                  Account
                </Link>
                <Link to={AppRoutes.BILLING} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} ${isRouteActive(location, AppRoutes.BILLING) ?
                  getBackgroundActive() :
                  ''}`}>
                  <FontAwesomeIcon icon={faCreditCard} className={`mr-2 
                    ${isRouteActive(location, AppRoutes.BILLING) ?
                      'text-white' :
                      'text-primary group-hover:text-white'} `} />
                  Billing
                </Link>
                <Link to={AppRoutes.HELP} className={`block px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()} 
                ${isRouteActive(location, AppRoutes.HELP) ?
                    getBackgroundActive() :
                    ''}`}>
                  <FontAwesomeIcon icon={faQuestion} className={`mr-2 
                    ${isRouteActive(location, AppRoutes.HELP) ?
                      'text-white' :
                      'text-primary group-hover:text-white'} `} />
                  Help
                </Link>
                <div className="border-t border-gray-200"></div>
                <button onClick={handleLogout} className={`w-full text-left px-4 py-2 rounded text-gray-800 group ${getBackgroundHover()}`}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 text-primary group-hover:text-white" />
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to={AppRoutes.PLANS} className={`block px-3 py-2 rounded text-base ${getBackgroundHover()} ${isRouteActive(location, AppRoutes.PLANS) ?
                  getBackgroundActive() :
                  ''}`}>
                  Prices
                </Link>
                <Link to={AppRoutes.HELP} className={`block px-3 py-2 rounded text-base ${getBackgroundHover()} ${isRouteActive(location, AppRoutes.HELP) ?
                  getBackgroundActive() :
                  ''}`}>
                  Help
                </Link>
                <Link to={AppRoutes.LOGIN} className={`block px-3 py-2 rounded text-base ${getBackgroundHover()} ${isRouteActive(location, AppRoutes.LOGIN) ?
                  getBackgroundActive() :
                  ''}`}>
                  Login
                </Link>
                <Link to={AppRoutes.SIGNUP} className={`block px-3 py-2 rounded text-base ${getBackgroundHover()} ${isRouteActive(location, AppRoutes.SIGNUP) ?
                  getBackgroundActive() :
                  ''}`}>
                  Signup
                </Link>
              </>
            )}
          </div>
        </div>
      )}
      {/*end mobile menu*/}
    </nav>
  );
};

export default Navigation;