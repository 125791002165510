import { AdsenseSettingsDTO } from "../dto/AdsenseSettingsDTO";

export const getAdsenseSettingsDisplayAdVertical1 = (): AdsenseSettingsDTO => {
  return {
    DataAdClient: "ca-pub-5070981482262089",
    DataAdSlot: "7196201106",
    DataAdFormat: "vertical",
  } as AdsenseSettingsDTO;
};

export const getAdsenseSettingsDisplayAdVertical2 = (): AdsenseSettingsDTO => {
  return {
    DataAdClient: "ca-pub-5070981482262089",
    DataAdSlot: "6840810016",
    DataAdFormat: "vertical",
  } as AdsenseSettingsDTO;
};

export const getAdsenseSettingsDisplayAdHorizontal1 = (): AdsenseSettingsDTO => {
  return {
    DataAdClient: "ca-pub-5070981482262089",
    DataAdSlot: "1717779184",
  } as AdsenseSettingsDTO;
};