import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import axiosInstance from '../helpers/axiosInstance';
import { useNavigate } from 'react-router-dom';
import NotificationService from '../../services/NotificationService';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum as LoadingMessage } from '../../helpers/LoadingMessageEnum';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import useLoadRecaptcha from '../../hooks/useLoadRecaptcha';
import GoogleOauthAuth from '../components/GoogleOauthAuth';
import FacebookInstagramOauthAuth from '../components/FacebookInstagramOauthAuth';
import { useAuthContext } from '../contexts/AuthContext';
import AuthLinkLabels from './AuthLinkLabels';
import { AuthLinkLabelsDisplayEnum } from '../helpers/AuthLinkLabelsDisplayEnum';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../config/FormSettings';
import { SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';

interface LoginAuthFormInputs {
    email: string;
    password: string;
}

interface LoginAuthProps {
    isAuthModal?: boolean;
    setIsModalOpen?: (isModalOpen: boolean) => void;
    setAuthLinkLabelsDisplayEnum?: (authLinkLabelsDisplayEnum: AuthLinkLabelsDisplayEnum) => void;
    authLinkLabelsDisplayEnum?: AuthLinkLabelsDisplayEnum;
    handleCampaignStateData?: () => void;
}

const LoginAuth: React.FC<LoginAuthProps> = ({ isAuthModal = false, setIsModalOpen, setAuthLinkLabelsDisplayEnum, authLinkLabelsDisplayEnum = AuthLinkLabelsDisplayEnum.LOGIN, handleCampaignStateData }) => {
    const { login } = useAuthContext();
    const notificationService = NotificationService();
    const { register, handleSubmit, formState: { errors } } = useForm<LoginAuthFormInputs>();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();
    const navigate = useNavigate();
    const isRecaptchaLoaded = useLoadRecaptcha();
    const { isLoading, setIsLoading } = useIsLoading();

    const onSubmit: SubmitHandler<LoginAuthFormInputs> = async (data: any) => {
        const handleSubmitForm = async () => {
            try {
                setIsLoading(true);
                setLoadingMessage(LoadingMessage.Message);

                if (!isRecaptchaLoaded) {
                    setLoadingMessage('Loading reCAPTCHA...');
                    setTimeout(handleSubmitForm, 1000);
                    return;
                }

                let recaptchaToken;
                try {
                    recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string, { action: 'submit' });
                } catch (error) {
                    notificationService.setMessage('reCAPTCHA failed. Please try again.', false);

                    setIsLoading(false);

                    return;
                }

                const formData = { ...data, recaptchaToken };

                const response = await axiosInstance.post(ApiRoutes.LOGIN, formData, {
                });

                const { token, refreshToken } = response.data;

                await login(token, refreshToken);

                if (isAuthModal) {
                    if (setIsModalOpen) {
                        setIsModalOpen(false);
                    }

                    notificationService.setMessage("Connected", true);

                    if (handleCampaignStateData) {
                        handleCampaignStateData();
                    }

                    setIsLoading(false);

                    //we don't need navigate, component will lose state
                }
                else {
                    notificationService.setMessage("Connected", true);

                    setIsLoading(false);

                    navigate(AppRoutes.DASHBOARD);
                }
            } catch (error: any) {
                if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
                    notificationService.setMessage(error.response.data.message, error.response.data.status);

                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <div className={`bg-white p-8 rounded ${isAuthModal ? "" : "shadow-md"} w-full max-w-md`}>
                <h2 className="text-2xl font-bold mb-6">Login</h2>
                <div className="w-full justify-center">
                    <FacebookInstagramOauthAuth
                        text="Login with"
                        setIsLoadingParent={setIsLoading}
                        isLoadingParent={isLoading}
                        isAuthModal={isAuthModal}
                        setIsModalOpen={setIsModalOpen}
                        handleCampaignStateData={handleCampaignStateData}
                        showOnly={SocialPlatformEnum.Facebook}
                    />
                </div>
                <div className="w-full flex justify-center">
                    <GoogleOauthAuth
                        text="Login with Google"
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        isAuthModal={isAuthModal}
                        setIsModalOpen={setIsModalOpen}
                        handleCampaignStateData={handleCampaignStateData}
                    />
                </div>
                <div className="flex items-center my-4">
                    <hr className="flex-grow border-gray-300" />
                    <span className="mx-2 text-gray-500">OR</span>
                    <hr className="flex-grow border-gray-300" />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                        <input
                            type="email"
                            {...register('email', {
                                required: 'Email is required',
                                maxLength: { value: 100, message: "Email cannot exceed 100 characters" }
                            })}
                            maxLength={100}
                            placeholder='Email'
                            className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                        />
                        {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                    </div>
                    <div className="mb-4">
                        <input
                            type="password"
                            {...register('password', {
                                required: 'Password is required',
                                maxLength: { value: 20, message: "Password cannot exceed 20 characters" }
                            })}
                            maxLength={20}
                            placeholder='Password'
                            className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                        />
                        {errors.password && <p className="text-red-500 text-sm">{errors.password.message}</p>}
                    </div>
                    <button
                        type="submit"
                        className={`${getButtonPrimaryStyleClass(false)} w-full p-2`}
                        disabled={isLoading}
                    >
                        {isLoading ? loadingMessage : "Login"}
                    </button>
                </form>
                <AuthLinkLabels
                    setAuthLinkLabelsDisplayEnum={setAuthLinkLabelsDisplayEnum}
                    authLinkLabelsDisplayEnum={authLinkLabelsDisplayEnum}
                />
            </div>
        </div>
    );
};

export default LoginAuth;