import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import Checkout from '../components/Checkout';

const Plans: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Plans</title>
      </Helmet>
      <Checkout  />
    </Layout>
  );
};

export default Plans;