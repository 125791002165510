import React, { ReactNode } from 'react';
import useIsMobile from '../hooks/useIsMobile';

interface LayoutContentProps {
  children: ReactNode;
}

const LayoutContent: React.FC<LayoutContentProps> = ({ children }) => {
  const { isUnder1200px } = useIsMobile();

  return (
    <>
      <div className={`w-full
        ${isUnder1200px ? '' : ''}`}>
        {children}
      </div>
    </>
  );
};

export default LayoutContent;