import React from 'react';
import './LoadingBar.css';

const LoadingBar: React.FC = () => {

    return (
        <div>
            <div className="relative w-full h-2 bg-gray-200 overflow-hidden rounded">
                <div className="absolute left-0 top-0 h-full bg-success loading-bar"></div>
            </div>
        </div>
    );
};

export default LoadingBar;