import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import InstagramUrlApp from '../components/campaign/apps/InstagramUrlApp';

const Home: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Instagram, TikTok, Youtube Comment Picker and Giveaways</title>
      </Helmet>
      <InstagramUrlApp />
    </Layout>
  );
};

export default Home;