import React, { useEffect, useState } from 'react';
import { useCampaignContext } from '../../contexts/CampaignContext';

interface CampaignCountdownProps {
    setIsCountdown: (isCountdown: boolean) => void;
    isCountdown: boolean;
}

const CampaignCountdown: React.FC<CampaignCountdownProps> = ({ setIsCountdown, isCountdown }) => {
    const { campaignSettingsDTO } = useCampaignContext();
    const [count, setCount] = useState(campaignSettingsDTO?.Countdown);

    useEffect(() => {
        if (count && count > 0) {
            const timer = setInterval(() => setCount(prevCount => prevCount ? prevCount - 1 : 0), 1000);
            return () => clearInterval(timer);
        } else if (count === 0) {
            setIsCountdown(false);
        }
    }, [count]);

    const radius = 162;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = circumference;
    const strokeDashoffset = count ? (count / (campaignSettingsDTO?.Countdown ?? 1)) * circumference : 0;

    return (
        <>
            {isCountdown && (
                <>
                    <div className="flex items-center p-8 justify-center">
                        <div className="relative flex items-center justify-center w-80 h-80 bg-white rounded-full shadow-lg overflow-hidden">
                            <svg
                                className="absolute w-full h-full transform rotate-90"
                                width="360"
                                height="360"
                                viewBox="0 0 360 360"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    className="text-gray-200"
                                    cx="180"
                                    cy="180"
                                    r={radius}
                                    stroke="currentColor"
                                    strokeWidth="35"
                                    strokeLinecap="round"
                                    strokeDasharray={strokeDasharray}
                                    strokeDashoffset="0"
                                    style={{ transition: 'stroke-dashoffset 1s linear' }}
                                />
                                <circle
                                    className="text-success"
                                    cx="180"
                                    cy="180"
                                    r={radius}
                                    stroke="currentColor"
                                    strokeWidth="35"
                                    strokeLinecap="round"
                                    strokeDasharray={strokeDasharray}
                                    strokeDashoffset={strokeDashoffset}
                                    style={{ transition: 'stroke-dashoffset 1s linear' }}
                                />
                            </svg>
                            <div className="relative flex items-center justify-center w-full h-full">
                                <div className="text-6xl font-bold text-primary hover:text-secondary">{count}</div>
                            </div>
                        </div>
                        <div className="absolute bottom-8 w-full text-center">
                            <p className="text-gray-600 text-sm"></p>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CampaignCountdown;