import React from 'react';
import Layout from '../../../components/Layout';
import { Helmet } from 'react-helmet';
import RandomNameWinnerListApp from '../../../components/campaign/apps/RandomNameWinnerListApp';

const RandomNameWinnerList: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Random Name Winner Giveaway Picker</title>
      </Helmet>
      <RandomNameWinnerListApp />
    </Layout>
  );
};

export default RandomNameWinnerList;