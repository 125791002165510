import React from 'react';
import axiosInstance from '../helpers/axiosInstance';
import NotificationService from '../../services/NotificationService';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { OauthFacebookTypeEnum as OauthFacebookType } from '../helpers/OauthFacebookType';
import { useNavigate } from 'react-router-dom';
import { SocialPlatformEnum as SocialPlatform, SocialPlatformEnum } from '../../helpers/SocialPlatformEnum';
import { useAuthContext } from '../contexts/AuthContext';
import { getDefinedRoutesWithoutParams } from '../../helpers/RoutesHelper';
import { getButtonPrimaryStyleClass } from '../../config/FormSettings';
import { useIsLoading } from '../../hooks/useIsLoading';

interface FacebookInstagramLoginButtonProps {
    text: string;
    isLoadingParent?: boolean;
    setIsLoadingParent?: (value: boolean) => void;
    isAuthModal?: boolean;
    setIsModalOpen?: (value: boolean) => void;
    setIsModalClose?: () => void;
    handleCampaignStateData?: () => void;
    showOnly?: SocialPlatformEnum;
    disableFlexClass?: boolean;
    isAuthorizeAccessToken?: boolean;
    setIsAuthorizedApi?: (value: boolean) => void;
    authorizeTokenType?: SocialPlatformEnum;
    forceCheckAuthModal?: (value: boolean) => void;
}

const FacebookInstagramOauthAuth: React.FC<FacebookInstagramLoginButtonProps> = ({ text, isLoadingParent, setIsLoadingParent, isAuthModal = false, setIsModalOpen, setIsModalClose, handleCampaignStateData, showOnly, disableFlexClass, isAuthorizeAccessToken, setIsAuthorizedApi, authorizeTokenType, forceCheckAuthModal }) => {
    const { isLoggedIn } = useAuthContext();
    const notificationService = NotificationService();
    const { loadingMessage } = useLoadingMessage();
    const navigate = useNavigate();
    const { login, setInstagramFacebookAccessToken, setFacebookAccessToken } = useAuthContext();

    const { isLoading, setIsLoading } = useIsLoading();

    const handleFacebookSuccess = async (response: any, code: OauthFacebookType = OauthFacebookType.AccessToken) => {
        try {
            setIsLoading(true);
            if (setIsLoadingParent)
                setIsLoadingParent(true);

            const formData = {
                tokenId: response.accessToken,
                type: code
            };

            const facebookOauthResponse = await axiosInstance.post(ApiRoutes.OAUTH_FACEBOOK_INSTAGRAM, formData);

            const { token, refreshToken, facebookAccessToken } = facebookOauthResponse.data;

            if (isAuthorizeAccessToken && setIsAuthorizedApi) {
                if (authorizeTokenType === SocialPlatformEnum.Instagram) {
                    await setInstagramFacebookAccessToken(facebookAccessToken);
                }
                else if (authorizeTokenType === SocialPlatformEnum.Facebook) {
                    await setFacebookAccessToken(facebookAccessToken);
                }

                //notify of authorization
                setIsAuthorizedApi(true);

                if (setIsModalOpen) {
                    setIsModalOpen(false);
                }
                if (setIsModalClose) {
                    setIsModalClose();
                }

                notificationService.setMessage("Connected", true);
            }
            else if (isAuthModal) {
                await login(token, refreshToken);

                if (setIsModalOpen) {
                    setIsModalOpen(false);
                }
                if (setIsModalClose) {
                    setIsModalClose();
                }

                notificationService.setMessage("Connected", true);

                if (handleCampaignStateData) {
                    handleCampaignStateData();
                }

                //we don't need navigate, component will lose state
            }
            else {
                await login(token, refreshToken);

                notificationService.setMessage("Connected", true);

                navigate(AppRoutes.DASHBOARD);
            }
        } catch (error: any) {
            if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
                notificationService.setMessage(error.response.data.message, error.response.data.status);
        } finally {
            setIsLoading(false);
            if (setIsLoadingParent)
                setIsLoadingParent(false);
        }
    };

    const handleLoginClick = async () => {
        setIsLoading(true);
        if (setIsLoadingParent)
            setIsLoadingParent(true);

        if (forceCheckAuthModal) {
            if (!(await isLoggedIn())) {
                forceCheckAuthModal(true);

                setIsLoading(false);
                if (setIsLoadingParent)
                    setIsLoadingParent(false);

                return;
            }
        }

        const appId = process.env.REACT_APP_FACEBOOK_APP_ID as string;
        let _redirectUri = process.env.REACT_APP_FRONTEND_URL + getDefinedRoutesWithoutParams(AppRoutes.REDIRECT_HANDLER) + SocialPlatform.Facebook;

        var scope = 'email,public_profile,read_insights';

        if (isAuthorizeAccessToken)
            scope = 'business_management,email,public_profile,pages_read_engagement,pages_read_user_content,instagram_basic,pages_show_list,instagram_manage_comments';

        const responseType = 'token';

        const facebookOAuthUrl = `https://www.facebook.com/dialog/oauth?client_id=${appId}&redirect_uri=${_redirectUri}&response_type=${responseType}&scope=${scope}&display=window`;

        const newTab = window.open(facebookOAuthUrl, '_blank');

        //RedirectHandler page tab
        const checkTabClosed = setInterval(() => {
            if (newTab) {
                if (newTab.closed) {
                    clearInterval(checkTabClosed);

                    var aborted = true;
                    const code = localStorage.getItem('facebookCode');

                    if (code) {
                        aborted = false;

                        const response = {
                            accessToken: code
                        };

                        localStorage.removeItem('facebookCode');

                        handleFacebookSuccess(response, OauthFacebookType.Code);

                        return;
                    }

                    const accessToken = localStorage.getItem('tempFacebookAccessToken');

                    if (accessToken) {
                        aborted = false;

                        const response = {
                            accessToken: accessToken
                        };

                        localStorage.removeItem('tempFacebookAccessToken');

                        handleFacebookSuccess(response, OauthFacebookType.AccessToken);

                        return;
                    }

                    if (aborted) {
                        setIsLoading(false);
                        if (setIsLoadingParent)
                            setIsLoadingParent(false);
                    }
                }
            }
        }, 500);
    };

    return (
        <>
            {!showOnly ? (
                <>
                    <div className="w-full flex justify-center">
                        <button
                            disabled={isLoading}
                            onClick={async () => await handleLoginClick()}
                            className={`w-full px-4 py-2 mb-2 ${getButtonPrimaryStyleClass(false)}`}
                        >
                            <FontAwesomeIcon icon={faInstagram} className="mr-2 text-instagram" />
                            {isLoading ? (
                                <>
                                    {loadingMessage}
                                </>
                            ) : (
                                <>
                                    {isLoadingParent ?
                                        (
                                            <>
                                                {loadingMessage}
                                            </>
                                        ) : (
                                            <>
                                                {`${text} Instagram`}
                                            </>
                                        )}
                                </>
                            )}
                        </button>
                    </div>
                    <div className="w-full flex justify-center">
                        <button
                            disabled={isLoading}
                            onClick={async () => await handleLoginClick()}
                            className={`w-full px-4 py-2 mb-2 ${getButtonPrimaryStyleClass(false)}`}
                        >
                            <FontAwesomeIcon icon={faFacebook} className="mr-2 text-facebook" />
                            {isLoading ? (
                                <>
                                    {loadingMessage}
                                </>
                            ) : (
                                <>
                                    {isLoadingParent ?
                                        (
                                            <>
                                                {loadingMessage}
                                            </>
                                        ) : (
                                            <>
                                                {`${text} Facebook`}
                                            </>
                                        )}
                                </>
                            )}
                        </button>
                    </div>
                </>
            ) : (
                <>
                    {showOnly === SocialPlatformEnum.Instagram ? (
                        <div className={`w-full ${!disableFlexClass ? "flex" : ""} justify-center`}>
                            <button
                                disabled={isLoading}
                                onClick={async () => await handleLoginClick()}
                                className={`w-full px-4 py-2 mb-2 ${getButtonPrimaryStyleClass(false)}`}
                            >
                                <FontAwesomeIcon icon={faInstagram} className="mr-2 text-instagram" />
                                {isLoading ? loadingMessage : text + " Instagram"}
                            </button>
                        </div>
                    ) : (
                        <div className={`w-full ${!disableFlexClass ? "flex" : ""} justify-center`}>
                            <button
                                disabled={isLoading}
                                onClick={async () => await handleLoginClick()}
                                className={`w-full px-4 py-2 mb-2 ${getButtonPrimaryStyleClass(false)}`}
                            >
                                <FontAwesomeIcon icon={faFacebook} className="mr-2 text-facebook" />
                                {isLoading ? loadingMessage : text + " Facebook"}
                            </button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default FacebookInstagramOauthAuth;