import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { WinnerDTO } from '../../dto/WinnerDTO';
import { useCampaignContext } from '../../contexts/CampaignContext';
import { prettifyNumbers } from '../../helpers/StringsHelper';
import { AutoSizer, InfiniteLoader, List, ListRowProps } from 'react-virtualized';
import 'react-virtualized/styles.css';

const CampaignParticipants: React.FC = () => {
    const {
        campaignParticipants,
        filteredCampaignParticipants,
        setFilteredCampaignParticipants,
        campaignSettingsDTO,
        setIsSavingSettings,
        setIsParticipantsFilterActive,
        isParticipantsFilterActive,
        isSavingSettings,
        setIsSettingsOpened,
        campaignStateDataDTO,
        isLoadingParticipants,
        setIsLoadingParticipants,
        isShufflingParticipants,
        setIsShufflingParticipants,
        isSavingCampaign
    } = useCampaignContext();

    const [loadedRows, setLoadedRows] = useState<number[]>([]);
    const [isParticipantsInFilter, setIsParticipantsInFilter] = useState<boolean>(true);

    useEffect(() => {
        if (campaignParticipants) {
            setFilteredCampaignParticipants(campaignParticipants);
        }
    }, [campaignParticipants]);

    useEffect(() => {
        if (campaignSettingsDTO) {
            let filtered;

            //filters
            if (campaignSettingsDTO.EliminateDuplicates) {
                const seenUsernames = new Set();
                filtered = campaignParticipants?.filter((winner) => {
                    if (seenUsernames.has(winner.Username)) {
                        return false;
                    } else {
                        seenUsernames.add(winner.Username);
                        return true;
                    }
                });
            }
            if (campaignSettingsDTO.FilterByMention && campaignSettingsDTO.Mention) {
                if (!filtered) {
                    filtered = campaignParticipants;
                }

                filtered = filtered?.filter((winner) => {
                    if (winner.Comment) {
                        return winner.Comment.includes(campaignSettingsDTO.Mention);
                    }
                    return false;
                });
            }
            if (campaignSettingsDTO.FilterByHashtag && campaignSettingsDTO.Hashtag) {
                if (!filtered) {
                    filtered = campaignParticipants;
                }

                filtered = filtered?.filter((winner) => {
                    if (winner.Comment) {
                        return winner.Comment.includes(campaignSettingsDTO.Hashtag);
                    }
                    return false;
                });
            }
            //filters - end

            //no filters applied check
            if (!filtered) {
                filtered = campaignParticipants;

                setIsParticipantsFilterActive(false);
            }
            else {
                setIsParticipantsFilterActive(true);
            }

            if (filtered && filtered.length > 0) {
                setIsParticipantsInFilter(true);
                setFilteredCampaignParticipants(filtered);
            }
            else {
                setIsParticipantsInFilter(false);
                setFilteredCampaignParticipants(null);
            }

            //we check here because filteredCampaignParticipants might be null or shuffled or equal and not updating state and useeffect does not capture null filteredCampaignParticipants
            if (isSavingSettings) {
                setIsSavingSettings(false);
                setIsSettingsOpened(false);
            }
        }
    }, [campaignSettingsDTO]);

    useEffect(() => {
        if (isShufflingParticipants) {
            const shuffleArray = (array: WinnerDTO[]) => {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
            };

            if (filteredCampaignParticipants) {
                const shuffledParticipants = shuffleArray([...filteredCampaignParticipants]);

                setFilteredCampaignParticipants(shuffledParticipants);
            }
        }
    }, [isShufflingParticipants]);

    useEffect(() => {
        if (filteredCampaignParticipants) {
            if (isLoadingParticipants) {
                setIsLoadingParticipants(false);
            }
            if (isShufflingParticipants) {
                setIsShufflingParticipants(false);
            }
            if (isSavingSettings) {
                setIsSavingSettings(false);
                setIsSettingsOpened(false);
            }
        }
    }, [filteredCampaignParticipants]);

    const isRowLoaded = ({ index }: { index: number }) => {
        return loadedRows.includes(index);
    };

    const loadMoreRows = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                setLoadedRows(prevRows => [
                    ...prevRows,
                    ...Array.from({ length: stopIndex - startIndex + 1 }, (_, i) => startIndex + i)
                ]);
                resolve();
            }, 1000);
        });
    };

    const rowRenderer = ({ key, index, style }: ListRowProps) => {
        if (!filteredCampaignParticipants)
            return null;

        const winner: WinnerDTO = filteredCampaignParticipants[index];
        if (!winner) return null;

        return (
            <li key={index} style={style} className="list-group-item flex items-center justify-between p-2 border-b border-gray-200">

                {/*fields*/}
                {/*left side*/}
                <div className='flex items-center'>
                    {winner.ProfileImage && (
                        <div className="text-center mr-4">
                            <img
                                alt={winner.UsernameDisplay}
                                src={winner.ProfileImage}
                                className="rounded-full w-12 h-full mx-auto"
                            />
                        </div>
                    )}
                    <div className="text-left font-bold">
                        {winner.UsernameDisplay}
                    </div>
                    <div className="text-left ml-2">
                        {winner.Comment && (
                            <>
                                {winner.Comment}
                            </>
                        )}
                    </div>
                </div>
                {/*left side - end*/}
                {/*right side*/}
                {winner.CommentLikeCount != null && (
                    <div className="text-right flex items-center">
                        <div className="text-right text-sm ml-2 mr-2">
                            {prettifyNumbers(winner.CommentLikeCount)}
                        </div>
                        <div className="text-right">
                            <FontAwesomeIcon icon={winner.SocialPlatformLikeIcon ?? faQuestion} className={winner.SocialPlatformClassName} />
                        </div>
                    </div>
                )}
                {/*right side - end*/}
                {/*fields - end*/}

            </li>
        );
    };

    return (
        <>
            {!isSavingCampaign && (
                <>
                    <div className="card bg-gradient-to-r from-primary to-secondary border-none rounded-lg mt-4 shadow-md">
                        <div className="card-header flex justify-between items-center p-4 text-white">
                            <div className="flex items-center">
                                Participants
                            </div>
                            <div className="flex">
                                <span>Total <i>
                                    {filteredCampaignParticipants ?
                                        isLoadingParticipants || isShufflingParticipants ?
                                            "Loading..." :
                                            prettifyNumbers(isParticipantsFilterActive ? filteredCampaignParticipants.length : campaignStateDataDTO?.ParticipantsCount) :
                                        isLoadingParticipants ?
                                            "Loading..." :
                                            0}
                                </i>
                                </span>
                            </div>
                            <div className="flex items-center">
                                <button onClick={() => setIsShufflingParticipants(true)}
                                    disabled={!isParticipantsInFilter}
                                >
                                    <FontAwesomeIcon icon={faShuffle} className="mr-2 text-white" />
                                    Shuffle
                                </button>
                            </div>
                        </div>
                        {(filteredCampaignParticipants && filteredCampaignParticipants.length > 0) &&
                            !isLoadingParticipants &&
                            !isShufflingParticipants &&
                            !isSavingSettings ?
                            (
                                <>
                                    <div className="list-group list-group-flush bg-white h-participants h-full max-h-80">

                                        <InfiniteLoader
                                            isRowLoaded={isRowLoaded}
                                            loadMoreRows={loadMoreRows}
                                            rowCount={filteredCampaignParticipants.length}
                                        >
                                            {({ onRowsRendered, registerChild }: any) => (
                                                <AutoSizer>
                                                    {({ height, width }: any) => (
                                                        <List
                                                            height={height}
                                                            onRowsRendered={onRowsRendered}
                                                            ref={registerChild}
                                                            rowCount={filteredCampaignParticipants.length}
                                                            rowHeight={60}
                                                            rowRenderer={rowRenderer}
                                                            width={width}
                                                        />
                                                    )}
                                                </AutoSizer>
                                            )}
                                        </InfiniteLoader>

                                    </div>
                                </>
                            ) :
                            (
                                <>
                                    <div className="list-group list-group-flush bg-white h-participants h-full max-h-80">
                                        <p className="p-2 text-base text-center font-semibold">
                                            {!isParticipantsInFilter && "No participants based on your filters"}
                                            {isSavingSettings && "Applying filters..."}
                                            {isShufflingParticipants && "Shuffling..."}
                                            {isLoadingParticipants && "Loading participants..."}
                                        </p>
                                    </div>
                                </>
                            )}
                    </div>
                </>
            )}
        </>
    );
};

export default CampaignParticipants;