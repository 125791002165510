export const getMaxLength = (string: string, maxLength: number = 50) => {
    if (string && string.length === 0)
        return "";

    if (!string)
        return "";

    const truncated = string.length > maxLength
        ? string.substring(0, maxLength) + '...'
        : string;

    return truncated;
};

export const ensureTrailingSlash = (url: string): string => {
    return url.endsWith('/') ? url : `${url}/`;
};

export const convertNumberToShortKFormatString = (number: number): string => {
    if (number < 1000) {
        return number.toString();
    } else if (number >= 1000 && number < 1_000_000) {
        return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
        return (number / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'mil';
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
        return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'bil';
    } else {
        return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'tril';
    }
};

export const prettifyNumbers = (number: number | null | undefined): string => {
    if (number == null)
        return "";

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};