import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getTextLinkStyleClass } from '../config/FormSettings';

const Maintenance: React.FC = () => {
   
    return (
        <div className="flex flex-col items-center justify-center bg-gray-100">
            <Helmet>
                <title>socialspicker.com - We'll be back soon</title>
            </Helmet>
            <div className="bg-primary p-6 rounded-lg shadow-lg text-center">
                <img src="/assets/logoWhite.svg" alt="Logo" className="w-full h-auto mb-5" />
                <p className="text-white text-center">Instagram Comment Picker and Giveaways</p>
                <h2 className="text-3xl text-white mb-4">We&apos;ll release soon, we will announce on our social media</h2>
                <p>
                    <Link className={`${getTextLinkStyleClass()} text-3xl font-semibold text-white`} to="https://instagram.com/socialspicker" target='_blank'>@socialspicker</Link>
                </p>
            </div>
        </div>
    );
};

export default Maintenance;