import React, { useEffect } from 'react';
import { getButtonPrimaryStyleClass } from '../config/FormSettings';
import CloseButton from './buttons/CloseButton';

interface NotificationToastProps {
  message: string;
  onClose: () => void;
  status: boolean | null;
  index: number;
}

const NotificationToast: React.FC<NotificationToastProps> = ({ message, onClose, status, index }) => {
  const bgColor = status === true ? 'bg-success' : status === false ? 'bg-error' : 'bg-primary';

  useEffect(() => {
    const timer = setTimeout(onClose, 4500);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div
      className="fixed left-0 right-0 flex justify-center mb-4 z-100"
      style={index > 0 ? { bottom: `${index * 4}rem` } : { bottom: "0rem" }}>
      <div className={`${bgColor} text-white px-4 py-2 rounded shadow-md flex items-center`}>
        <span>{message}</span>
        <div className='ml-4'>
          <CloseButton
            text="Close"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationToast;