import { useEffect, useState } from 'react';
import loggerService from '../services/LoggerService';

const useLoadRecaptcha = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    if (!siteKey) {
      loggerService.error('RECAPTCHA siteKey is not defined');
      return;
    }

    const checkRecaptcha = () => {
      if (window.grecaptcha && typeof window.grecaptcha.execute === 'function' && typeof window.grecaptcha.ready === 'function') {
        window.grecaptcha.ready(() => {
          showRecaptchaBadge();
          setIsLoaded(true);
        });
      } else {
        setIsLoaded(false);
        setTimeout(checkRecaptcha, 1000);
      }
    };

    const loadRecaptchaScript = () => {
      const existingScript = document.getElementById('recaptcha-script');
      if (!existingScript) {
        const script = document.createElement('script');
        script.id = 'recaptcha-script';
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        script.onload = () => {
          checkRecaptcha();
        };
        script.onerror = (event) => {
          // Handle script load error
          loggerService.error(event);
        };
        document.head.appendChild(script);
      }
      else {
        checkRecaptcha();
      }
    };

    loadRecaptchaScript();

    return () => {
      hideRecaptchaBadge();
    };
  }, []);

  const hideRecaptchaBadge = () => {
    const recaptchaBadge = document.querySelector('.grecaptcha-badge') as HTMLDivElement;
    if (recaptchaBadge) {
      recaptchaBadge.style.display = 'none';
    }
  };

  const showRecaptchaBadge = () => {
    const recaptchaBadge = document.querySelector('.grecaptcha-badge') as HTMLDivElement;
    if (recaptchaBadge) {
      recaptchaBadge.style.display = 'block';
    }
  };

  return isLoaded;
};

export default useLoadRecaptcha;