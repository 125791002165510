import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import { useAuthContext } from '../../auth/contexts/AuthContext';
import AdBlockModal from '../../components/modals/AdBlockModal';

const FlipCoin: React.FC = () => {
  const { setShowGoogleAdsense } = useAuthContext();

  useEffect(() => {
    setShowGoogleAdsense(true);

    return () => {
      setShowGoogleAdsense(false);
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Flip a Coin Online, Coin Toss</title>
      </Helmet>
      <AdBlockModal />
    </Layout>
  );
};

export default FlipCoin;