import { useLocation } from 'react-router-dom';

const useIsAppsPagesRoute = (): boolean => {
  const location = useLocation();

  const isAppsInUrl = location.pathname.includes('/apps/');

  return isAppsInUrl;
};

export default useIsAppsPagesRoute;