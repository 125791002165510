import React from 'react';
import LoadingBar from './LoadingBar/LoadingBar';
import { LoadingMessageEnum } from '../../helpers/LoadingMessageEnum';

interface LoadingScreenProps {
    loadingMessage?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ loadingMessage = LoadingMessageEnum.Message }) => {

    return (
        <>
            <div className="p-4 text-center w-full max-w-2xl mx-auto mt-4">
                <div className="p-4 text-lg font-semibold">
                    {loadingMessage}
                </div>
                <div>
                    <LoadingBar />
                </div>
            </div>
        </>
    );
};

export default LoadingScreen;