import React, { useEffect, useState } from 'react';
import NotificationService from '../../../services/NotificationService';
import { useIsLoading } from '../../../hooks/useIsLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingMessageEnum as LoadingMessage } from '../../../helpers/LoadingMessageEnum';
import { useLoadingMessage } from '../../../hooks/useLoadingMessage';
import { SubmitHandler, useForm } from 'react-hook-form';
import loggerService from '../../../services/LoggerService';
import { RoutesEnum as AppRoutes } from '../../../routes/RoutesEnum';
import { SocialPlatformEnum as SocialPlatform } from '../../../helpers/SocialPlatformEnum';
import { CampaignStateDataDTO } from '../../../dto/CampaignStateDataDTO';
import { YesNoEnum } from '../../../helpers/YesNoEnum';
import { getButtonPrimaryStyleClass, getInputTextStyleClass } from '../../../config/FormSettings';
import { useCampaignContext } from '../../../contexts/CampaignContext';
import { CampaignTypeEnum } from '../../../helpers/CampaignTypeEnum';
import { isRouteActive } from '../../../helpers/RoutesHelper';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../auth/contexts/AuthContext';
import { faListNumeric, faPaste, faUsers } from '@fortawesome/free-solid-svg-icons';
import CampaignPreview from '../CampaignPreview';
import { WinnerDTO } from '../../../dto/WinnerDTO';
import { CampaignPreviewDTO } from '../../../dto/CampaignPreviewDTO';
import { getMaxLength } from '../../../helpers/StringsHelper';
import Tooltip from '../../Tooltip';

interface RandomNameWinnerListAppFormInputs {
    list: string;
}

const YoutubeUrlApp: React.FC = () => {
    const { setCampaignStateDataDTO, campaignStateDataDTO, setCampaignParticipants, setCampaignPreviewDTO } = useCampaignContext();
    const { setIsAllowLoggedInFromHome } = useAuthContext();
    const location = useLocation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<RandomNameWinnerListAppFormInputs>();
    const notificationService = NotificationService();
    const { isLoading, setIsLoading } = useIsLoading();
    const { loadingMessage, setLoadingMessage } = useLoadingMessage();

    const [campaignDisplayPreview, setCampaignDisplayPreview] = useState<boolean>(false);

    const handleCampaignStateData = async (list: string) => {
        const campaignStateDataDTO: CampaignStateDataDTO = {
            SocialPlatform: SocialPlatform.List,
            CampaignType: CampaignTypeEnum.List,
            Referrer: AppRoutes.APP_RANDOM_NAME_WINNERS,
            Api: YesNoEnum.No,
            SocialPlatformIcon: faListNumeric,
            SocialPlatformClassName: "text-randomNameWinner",
            CommentsDisplayLabel: "Participants",
            CommentsSocialIcon: faUsers,
            ParticipantsCount: 0,
        };

        const participantsNames: string[] = list.split('\n').map(line => line.trim()).filter(line => line !== '');

        const participants: WinnerDTO[] = participantsNames.map((participantName: any) => ({
            Username: getMaxLength(participantName, 100),
            UsernameDisplay: participantName,
            SocialPlatformIcon: campaignStateDataDTO.SocialPlatformIcon,
            SocialPlatformLikeIcon: campaignStateDataDTO.SocialPlatformLikeIcon,
            SocialPlatformClassName: campaignStateDataDTO.SocialPlatformClassName,
            IsValid: true,
        }));

        campaignStateDataDTO.ParticipantsCount = participants.length;

        setCampaignParticipants(participants);

        const CampaignPreviewDTO: CampaignPreviewDTO = {
            CommentsCount: participants.length,
        };

        setCampaignPreviewDTO(CampaignPreviewDTO);

        setCampaignDisplayPreview(true);
        setCampaignStateDataDTO(campaignStateDataDTO);
    }

    useEffect(() => {
        if (isRouteActive(location, AppRoutes.HOME)) {
            setIsAllowLoggedInFromHome(true);
        }

        return () => {
            setIsAllowLoggedInFromHome(false);
        };
    }, [campaignStateDataDTO]);

    const onSubmit: SubmitHandler<RandomNameWinnerListAppFormInputs> = async (data) => {
        const handleSubmitForm = async () => {
            setIsLoading(true);

            try {
                await handleCampaignStateData(data.list);
            } catch (error) {
                notificationService.setMessage('Failed, try again.', false);

                loggerService.error(error);
            }
            finally {
                setIsLoading(false);
            }
        };

        handleSubmitForm();
    };

    return (
        <>
            <div className="flex flex-col items-center p-4 mt-4">
                {(campaignDisplayPreview && campaignStateDataDTO) ? (
                    <>
                        <CampaignPreview />
                    </>
                ) : (
                    <>
                        <h1 className="text-2xl font-bold mb-4">Random Name Picker</h1>
                        <p className="text-center mb-4">Pick a winner from a list of participants</p>
                        <div className="w-full max-w-md text-left">
                            <Tooltip text="Paste">
                                <FontAwesomeIcon onClick={async () => { setValue("list", await navigator.clipboard.readText()) }} icon={faPaste} className="mr-2 text-primary cursor-pointer" />
                            </Tooltip>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md">
                            <div className="flex items-center py-2">
                                <FontAwesomeIcon icon={faListNumeric} className="mr-2 text-randomNameWinner" />
                                <textarea
                                    {...register('list', { required: 'Participants required' })}
                                    placeholder="List of participants on each row"
                                    rows={10}
                                    className={`${getInputTextStyleClass()} w-full py-2 px-2`}
                                ></textarea>
                            </div>
                            <div className="w-full text-center py-1">
                                {errors.list && <p className="text-red-500 text-sm">{errors.list.message}</p>}
                            </div>
                            <button
                                type="submit"
                                className={`${getButtonPrimaryStyleClass(true)} w-full p-2`}
                                disabled={isLoading}
                            >
                                {isLoading ? loadingMessage : "Start"}
                            </button>
                        </form>
                    </>
                )}
            </div>
        </>
    );
};

export default YoutubeUrlApp;