import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { RoutesEnum } from '../routes/RoutesEnum';
import { faX } from '@fortawesome/free-solid-svg-icons';
import useIsMobile from '../hooks/useIsMobile';
import { getTextLinkStyleClass } from '../config/FormSettings';

const Footer: React.FC = () => {
  const { isMobile } = useIsMobile();
  const columnsGap = isMobile ? "gap-2" : "gap-8";

  return (
    <>
      <footer aria-labelledby="footer-heading" className="bg-white py-8">
        <div className={`container mx-auto grid grid-cols-1 md:grid-cols-3 ${columnsGap}`}>

          <div className="space-y-6 p-2">
            <Link to={RoutesEnum.HOME} className="w-full items-center">
              <img src={"/assets/logoBlack.svg"} alt="Logo" className={`w-full h-auto max-w-3xs ${isMobile && "mx-auto"}`} />
            </Link>
            <p className="">
              Create Giveaways from Social Media platforms, pick a random comment winner from Instagram and others
            </p>
            <div className={`space-x-6 ${isMobile && "text-center"}`}>
              <Link to="#" className="group">
                <FontAwesomeIcon icon={faInstagram} className='text-instagram text-2xl group-hover:text-primary' />
              </Link>
              <Link to="#" className="group">
                <FontAwesomeIcon icon={faTiktok} className='text-tiktok text-2xl group-hover:text-primary' />
              </Link>
              <Link to="#" className="group">
                <FontAwesomeIcon icon={faX} className='text-twitter text-2xl group-hover:text-primary' />
              </Link>
              <Link to="#" className="group">
                <FontAwesomeIcon icon={faFacebook} className='text-facebook text-2xl group-hover:text-primary' />
              </Link>
            </div>
          </div>

          <div className={`grid grid-cols-2 ${columnsGap} p-4`}>
            <div>
              <h3 className="text-sm font-semibold">Giveaways / Promotions</h3>
              <ul className="mt-4 space-y-4">
                <li><Link to={RoutesEnum.APP_INSTAGRAM_URL} className={`${getTextLinkStyleClass()}`}>Instagram Giveaway</Link></li>
                <li><Link to={RoutesEnum.APP_TIKTOK_URL} className={`${getTextLinkStyleClass()}`}>TikTok Giveaway</Link></li>
                <li><Link to={RoutesEnum.APP_YOUTUBE_URL} className={`${getTextLinkStyleClass()}`}>Youtube Giveaway</Link></li>
                <li><Link to={RoutesEnum.APP_TWITTER_URL} className={`${getTextLinkStyleClass()}`}>Twitter / X Giveaway</Link></li>
                <li><Link to={RoutesEnum.APP_RANDOM_NAME_WINNERS} className={`${getTextLinkStyleClass()}`}>Random Name Winner Giveaway</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold">Tools</h3>
              <ul className="mt-4 space-y-4">
                <li><Link to={RoutesEnum.CAMPAIGN_SEARCH_BY_CODE} className={`${getTextLinkStyleClass()}`}>Search Giveaway by Code</Link></li>
                <li><Link to={RoutesEnum.APP_CREATE_GIVEAWAY_IMAGE} className={`${getTextLinkStyleClass()}`}>Create Giveaway Image</Link></li>
              </ul>
            </div>
            <div>

            </div>
          </div>
          <div className={`grid grid-cols-2 ${columnsGap} p-4`}>
            <div>
              <h3 className="text-sm font-semibold">Random</h3>
              <ul className="mt-4 space-y-4">
                <li><Link to={RoutesEnum.APP_ROLL_DICE} className={`${getTextLinkStyleClass()}`}>Roll Dice</Link></li>
                <li><Link to={RoutesEnum.APP_RANDOM_NUMBERS} className={`${getTextLinkStyleClass()}`}>Random Numbers</Link></li>
                <li><Link to={RoutesEnum.APP_FLIP_COIN} className={`${getTextLinkStyleClass()}`}>Flip a Coin</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold">Resources</h3>
              <ul className="mt-4 space-y-4">
                <li><Link to={RoutesEnum.PLANS} className={`${getTextLinkStyleClass()}`}>Prices</Link></li>
                <li><Link to={RoutesEnum.HELP} className={`${getTextLinkStyleClass()}`}>Help</Link></li>
                <li><Link to={RoutesEnum.TERMS} className={`${getTextLinkStyleClass()}`}>Terms & Conditions</Link></li>
                <li><Link to={RoutesEnum.PRIVACY_POLICY} className={`${getTextLinkStyleClass()}`}>Privacy Policy</Link></li>
              </ul>
            </div>
          </div>

        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-100 mt-8 pt-8">
          <p className="text-center text-lg">
            &copy; socialspicker.com. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;