import React from 'react';
import Layout from '../../../components/Layout';
import { Helmet } from 'react-helmet';
import TiktokUrlApp from '../../../components/campaign/apps/TiktokUrlApp';

const InstagramUrl: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Tiktok Giveaway Comment Picker</title>
      </Helmet>
      <TiktokUrlApp />
    </Layout>
  );
};

export default InstagramUrl;