import { createContext, useState, useContext, ReactNode } from 'react';
import { WinnerDTO } from '../dto/WinnerDTO';
import { CampaignViewEnum } from '../helpers/CampaignViewEnum';
import { CampaignSettingsDTO } from '../dto/CampaignSettingsDTO';
import { useNavigate } from 'react-router-dom';
import { CampaignStateDataDTO } from '../dto/CampaignStateDataDTO';
import { CampaignPreviewDTO } from '../dto/CampaignPreviewDTO';

interface CampaignContextProps {
    unmountCampaignRevertDefaults: () => void;
    campaignParticipants: WinnerDTO[] | null;
    setCampaignParticipants: (value: WinnerDTO[] | null | ((prevParticipants: WinnerDTO[] | null) => WinnerDTO[])) => void;
    filteredCampaignParticipants: WinnerDTO[] | null;
    setFilteredCampaignParticipants: (value: WinnerDTO[] | null) => void;
    isSettingsOpened: boolean;
    setIsSettingsOpened: (value: boolean) => void;
    campaignWinners: WinnerDTO[] | null;
    setCampaignWinners: (value: WinnerDTO[] | null | ((prevState: WinnerDTO[] | null) => WinnerDTO[] | null)) => void;
    campaignViewEnum: CampaignViewEnum | null;
    setCampaignViewEnum: (value: CampaignViewEnum | null) => void;
    campaignSettingsDTO: CampaignSettingsDTO | null;
    setCampaignSettingsDTO: (value: CampaignSettingsDTO | null) => void;
    isSavingSettings: boolean;
    setIsSavingSettings: (value: boolean) => void;
    isSavingCampaign: boolean;
    setIsSavingCampaign: (value: boolean) => void;
    isLoadingParticipants: boolean;
    setIsLoadingParticipants: (value: boolean) => void;
    isParticipantsFilterActive: boolean;
    setIsParticipantsFilterActive: (value: boolean) => void;
    isShufflingParticipants: boolean;
    setIsShufflingParticipants: (value: boolean) => void;
    navigateReferrer: () => void;
    isAuthModalOpen: boolean;
    setIsAuthModalOpen: (value: boolean) => void;
    campaignStateDataDTO: CampaignStateDataDTO | null;
    setCampaignStateDataDTO: (value: CampaignStateDataDTO | null | ((prevState: CampaignStateDataDTO | null) => CampaignStateDataDTO | null)) => void;
    campaignPreviewDTO: CampaignPreviewDTO | null;
    setCampaignPreviewDTO: (value: CampaignPreviewDTO | null | ((prevState: CampaignPreviewDTO | null) => CampaignPreviewDTO | null)) => void;
    setReplacementsCurrent: (value: number) => void;
    replacementsCurrent: number;
    setCampaignIdCurrent: (value: number | null) => void;
    campaignIdCurrent: number | null;
}

const CampaignContext = createContext<CampaignContextProps | undefined>(undefined);

const CampaignProvider = ({ children }: { children: ReactNode }) => {
    const navigate = useNavigate();

    const [campaignViewEnum, setCampaignViewEnum] = useState<CampaignViewEnum | null>(null);
    const [campaignSettingsDTO, setCampaignSettingsDTO] = useState<CampaignSettingsDTO | null>(null);
    const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);
    const [isSavingCampaign, setIsSavingCampaign] = useState<boolean>(false);
    const [isSettingsOpened, setIsSettingsOpened] = useState<boolean>(true);
    const [campaignParticipants, setCampaignParticipants] = useState<WinnerDTO[] | null>(null);
    const [isLoadingParticipants, setIsLoadingParticipants] = useState<boolean>(true);
    const [isParticipantsFilterActive, setIsParticipantsFilterActive] = useState<boolean>(true);
    const [isShufflingParticipants, setIsShufflingParticipants] = useState<boolean>(false);
    const [campaignWinners, setCampaignWinners] = useState<WinnerDTO[] | null>(null);
    const [filteredCampaignParticipants, setFilteredCampaignParticipants] = useState<WinnerDTO[] | null>(null);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
    const [campaignStateDataDTO, setCampaignStateDataDTO] = useState<CampaignStateDataDTO | null>(null);
    const [campaignPreviewDTO, setCampaignPreviewDTO] = useState<CampaignPreviewDTO | null>(null);
    const [replacementsCurrent, setReplacementsCurrent] = useState<number>(0);
    const [campaignIdCurrent, setCampaignIdCurrent] = useState<number | null>(null);

    const unmountCampaignRevertDefaults = () => {
        setCampaignViewEnum(null);
        setCampaignSettingsDTO(null);
        setCampaignParticipants(null);
        setCampaignWinners(null);
        setFilteredCampaignParticipants(null);
        setIsAuthModalOpen(false);
        setCampaignStateDataDTO(null);
        setIsSettingsOpened(true);
        setIsSavingSettings(false);
        setIsLoadingParticipants(true);
        setIsShufflingParticipants(false);
        setCampaignPreviewDTO(null);
    }

    const navigateReferrer = () => {
        unmountCampaignRevertDefaults();

        if (campaignStateDataDTO?.Referrer) {
            navigate(campaignStateDataDTO.Referrer);
        }
    }

    return (
        <CampaignContext.Provider value={{
            unmountCampaignRevertDefaults,
            campaignParticipants,
            setCampaignParticipants,
            filteredCampaignParticipants,
            setFilteredCampaignParticipants,
            isLoadingParticipants,
            setIsLoadingParticipants,
            isParticipantsFilterActive,
            setIsParticipantsFilterActive,
            isShufflingParticipants,
            setIsShufflingParticipants,
            campaignWinners,
            setCampaignWinners,
            campaignViewEnum,
            setCampaignViewEnum,
            campaignSettingsDTO,
            setCampaignSettingsDTO,
            isSavingSettings,
            setIsSavingSettings,
            isSavingCampaign,
            setIsSavingCampaign,
            isSettingsOpened,
            setIsSettingsOpened,
            navigateReferrer,
            isAuthModalOpen,
            setIsAuthModalOpen,
            campaignStateDataDTO,
            setCampaignStateDataDTO,
            campaignPreviewDTO,
            setCampaignPreviewDTO,
            setReplacementsCurrent,
            replacementsCurrent,
            setCampaignIdCurrent,
            campaignIdCurrent,
        }}>
            {children}
        </CampaignContext.Provider>
    );
};

export const useCampaignContext = () => {
    const context = useContext(CampaignContext);
    if (!context) {
        throw new Error('useCampaignContext must be used within a CampaignProvider');
    }
    return context;
};

export default CampaignProvider;