import React, { useRef, useEffect } from 'react';

class Particle {
  x: number;
  y: number;
  size: number;
  speedX: number;
  speedY: number;
  color: string;
  rotation: number; // Rotation angle
  rotationSpeed: number; // Speed of rotation

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.size = Math.random() * 5 + 5; // Larger size for confetti
    this.speedX = (Math.random() - 0.5) * 3; // Random horizontal speed
    this.speedY = Math.random() * 5 + 2; // Faster downward speed for falling effect
    this.color = `hsl(${Math.random() * 360}, 100%, 50%)`;
    this.rotation = Math.random() * Math.PI * 2; // Random initial rotation
    this.rotationSpeed = Math.random() * 0.1; // Random rotation speed
  }

  update() {
    this.x += this.speedX;
    this.y += this.speedY;
    this.size *= 0.98; // Slightly smaller decay
    this.rotation += this.rotationSpeed; // Update rotation
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.save(); // Save current state
    ctx.fillStyle = this.color;
    ctx.translate(this.x, this.y); // Move origin to particle position
    ctx.rotate(this.rotation); // Rotate canvas
    ctx.fillRect(-this.size / 2, -this.size / 2, this.size, this.size); // Draw square (confetti piece)
    ctx.restore(); // Restore original state
  }
}

const ConfettiCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const particles = useRef<Particle[]>([]); // Use useRef to persist particle array

  const draw = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (ctx && canvas) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.current.forEach((particle) => {
        particle.update();
        particle.draw(ctx);
      });

      // Create new particles at random horizontal positions and from the top
      if (particles.current.length < 300) {
        particles.current.push(new Particle(Math.random() * canvas.width, 0));
      }

      // Remove particles that are too small or have moved off screen
      particles.current = particles.current.filter(p => p.size > 0.5 && p.x >= 0 && p.x <= canvas.width && p.y >= 0 && p.y <= canvas.height);

      requestAnimationFrame(draw);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      draw();

      const handleResize = () => {
        if (canvas) {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        }
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', pointerEvents: 'none' }} />;
};

export default ConfettiCanvas;