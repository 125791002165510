import { Location } from "react-router-dom";

export const routeContains = (location: Location, path: string) => {
  const pathWithoutToken = path.split('/:')[0];

  return location.pathname.includes(pathWithoutToken);
};

export const getDefinedRoutesWithoutParams = (route: string) => {
  var routeWithoutParam = route.split('/:')[0];
  routeWithoutParam += "/";

  return routeWithoutParam;
};

export const isRouteActive = (location: Location, path: string) => {
  return location.pathname === path;
};

export const getBaseUrl = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.origin}/`;
  } catch (error) {
    console.error('Invalid URL:', error);
    return '';
  }
};