import { ParticipantCreditsPackagesDTO } from "../dto/ParticipantCreditsPackagesDTO";
import { WinnerDTO } from "../dto/WinnerDTO";
import { getGenerateRandomInt } from "../helpers/NumberHelper";

export const version = "1.0.3";
export const isTestComponentAlwaysDisplay = (process.env.NODE_ENV === 'development') && false;
export const isMaintenance = (process.env.NODE_ENV === 'development') ? false : true;
const isSimulateData = (process.env.NODE_ENV === 'development') ? false : false;
export const isDevelopmentReactStrictMode = false;
export const allowPwaOnLocalhost = false;
export const keyParamAllowProduction = "ASdijasdhsandAKJHKJDASHASODOefndksjnfejwhasjd";

export const minParticipantsCount: number = 3;
export const paginationSize = 15;

/*
used in fetching campaign data (comments, etc.) via API
*/
export const apiNoFetchExceptionDelayRequestsMs = 1000;
export const apiNoFetchMaxRetry = 20;
/*
used in fetching general data via API
*/
export const defaultWaitBetweenRequestsMs = 2000;
export const defaultMaxRetry = 20;

export const getGenerateSimulateDataParticipants = (count: number) => {
    const participants: WinnerDTO[] = [];
    for (let i = 0; i < count; i++) {
        participants.push({
            Username: `User${"test"}`,
            UsernameDisplay: `@User${"test"}`,
            ProfileImage: `https://randomuser.me/api/portraits/men/${getGenerateRandomInt(1, 99)}.jpg`,
            Comment: `This is a comment number ${i + 1}`,
            CommentLink: `https://example.com/comment/${i + 1}`,
            CommentLikeCount: getGenerateRandomInt(0, 999999),
            ProfileLink: `https://example.com/profile/${i + 1}`,
            IsValid: true,
        });
    }
    return participants;
}
export const simulateData = {
    isSimulateData: isSimulateData,
    Participants: getGenerateSimulateDataParticipants(5000)
};

export const participantCreditPackages: ParticipantCreditsPackagesDTO[] = [
    {
        ParticipantCredits: 500,
        Price: 1.99
    },
    {
        ParticipantCredits: 3000,
        Price: 4.90
    },
    {
        ParticipantCredits: 5000,
        Price: 5.90
    },
    {
        ParticipantCredits: 10000,
        Price: 7.90
    },
    {
        ParticipantCredits: 20000,
        Price: 9.90
    },
    {
        ParticipantCredits: 30000,
        Price: 11.90
    },
];