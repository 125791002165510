import { jwtDecode } from 'jwt-decode';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import { UserDataModel as UserData } from '../../models/UserDataModel';
import axiosInstance, { CustomAxiosRequestConfig } from '../helpers/axiosInstance';
import NotificationService from '../../services/NotificationService';
import { AuthStorageEnum } from '../helpers/AuthStorageEnum';

interface AuthMethods {
  logout: (navigate: (path: string) => void) => Promise<void>;
  login: (token: string, refreshToken: string) => Promise<void>;
  getToken: () => Promise<string | null>;
  getRefreshToken: () => Promise<string | null>;
  setInstagramFacebookAccessToken: (facebookAccessToken: string) => Promise<void>;
  getInstagramFacebookAccessToken: () => Promise<string | null>;
  removeInstagramFacebookAccessToken: () => Promise<void>;
  setFacebookAccessToken: (facebookAccessToken: string) => Promise<void>;
  getFacebookAccessToken: () => Promise<string | null>;
  removeFacebookAccessToken: () => Promise<void>;
  isLoggedIn: () => Promise<boolean>;
  hasRole: (role: string) => Promise<boolean>;
  getRole: () => Promise<string | null>;
  getUserDataFromToken: (navigate: (path: string) => void, forceRefresh: boolean) => Promise<UserData | null>;
}

let cachedToken: string | null = null;
let userData: UserData | null = null;
const notificationService = NotificationService();

const AuthService = (): AuthMethods => {

  const logout = async (navigate: (path: string) => void): Promise<void> => {
    localStorage.removeItem(AuthStorageEnum.token);
    localStorage.removeItem(AuthStorageEnum.refreshToken);
    localStorage.removeItem(AuthStorageEnum.facebookAccessToken);
    localStorage.removeItem(AuthStorageEnum.instagramFacebookAccessToken);

    cachedToken = null;

    navigate(AppRoutes.LOGIN);
  };

  const login = async (token: string, refreshToken: string): Promise<void> => {
    localStorage.setItem(AuthStorageEnum.token, token);
    localStorage.setItem(AuthStorageEnum.refreshToken, refreshToken);
  };

  const getToken = async (): Promise<string | null> => {
    const token = localStorage.getItem(AuthStorageEnum.token);
    if (!token)
      return null;
    else
      return token;
  };

  const getRefreshToken = async (): Promise<string | null> => {
    const refreshToken = localStorage.getItem(AuthStorageEnum.refreshToken);
    if (!refreshToken)
      return null;
    else
      return refreshToken;
  };

  const setInstagramFacebookAccessToken = async (facebookAccessToken: string): Promise<void> => {
    localStorage.setItem(AuthStorageEnum.instagramFacebookAccessToken, facebookAccessToken);
  };

  const removeInstagramFacebookAccessToken = async (): Promise<void> => {
    localStorage.removeItem(AuthStorageEnum.instagramFacebookAccessToken);
  };

  const getInstagramFacebookAccessToken = async (): Promise<string | null> => {
    const facebookAccessToken = localStorage.getItem(AuthStorageEnum.instagramFacebookAccessToken);
    if (!facebookAccessToken)
      return null;
    else
      return facebookAccessToken;
  };

  const setFacebookAccessToken = async (facebookAccessToken: string): Promise<void> => {
    localStorage.setItem(AuthStorageEnum.facebookAccessToken, facebookAccessToken);
  };

  const removeFacebookAccessToken = async (): Promise<void> => {
    localStorage.removeItem(AuthStorageEnum.facebookAccessToken);
  };

  const getFacebookAccessToken = async (): Promise<string | null> => {
    const facebookAccessToken = localStorage.getItem(AuthStorageEnum.facebookAccessToken);
    if (!facebookAccessToken)
      return null;
    else
      return facebookAccessToken;
  };

  const isLoggedIn = async (): Promise<boolean> => {
    const value = await getToken();

    return (value != null);
  };

  const hasRole = async (role: string): Promise<boolean> => {
    const _role = await getRole();
    if (!_role)
      return false;

    return (await _role).includes(role);
  };

  const getRole = async (): Promise<string | null> => {
    if (!(await isLoggedIn())) return null;

    const token = await getToken();
    if (!token) return null;

    try {
      const decodedToken: { role: string } = jwtDecode(token);
      return decodedToken.role || null;
    } catch (error) {
      return null;
    }
  };

  const getUserDataFromToken = async (navigate: (path: string) => void, forceRefresh: boolean = false): Promise<UserData | null> => {
    const token = await getToken();

    if (!token) {
      cachedToken = null;
      userData = null;
      return userData;
    }

    if (forceRefresh || cachedToken !== token) {
      try {
        const decodedToken: {
          //id
          nameid: number,
          //role
          role: string,
        } = jwtDecode(token);

        const response = await axiosInstance.get(ApiRoutesEnum.GET_USER, { authNeeded: true } as CustomAxiosRequestConfig);

        userData = {
          Id: decodedToken.nameid,
          Email: response.data.email,
          FirstName: response.data.firstName,
          LastName: response.data.lastName,
          Verified: response.data.verified,
          Role: decodedToken.role
        };

        cachedToken = token;
      } catch (error: any) {
        if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
          notificationService.setMessage(error.response.data.message, error.response.data.status);

        notificationService.setMessage("Please re-authenticate", false);

        await logout(navigate);
        userData = null;
        return userData;
      }
    }

    return userData;
  };

  return {
    logout,
    login,
    getToken,
    getRefreshToken,
    setInstagramFacebookAccessToken,
    getInstagramFacebookAccessToken,
    removeInstagramFacebookAccessToken,
    setFacebookAccessToken,
    getFacebookAccessToken,
    removeFacebookAccessToken,
    isLoggedIn,
    hasRole,
    getRole,
    getUserDataFromToken
  };
};

export default AuthService;