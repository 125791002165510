export enum SocialPlatformEnum {
    Facebook = 'Facebook',
    Instagram = 'Instagram',
    Tiktok = 'Tiktok',
    Youtube = 'Youtube',
    Twitter = 'Twitter',
    List = "List",
}

export const getSocialPlatformEnumNameValueList = (): { name: string, value: any }[] => {
    return Object.entries(SocialPlatformEnum).map(([key, value]) => {
        return { name: `${value} Giveaway`, value: value };
    });
};