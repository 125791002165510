import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axiosInstance from '../helpers/axiosInstance';
import NotificationService from '../../services/NotificationService';
import loggerService from '../../services/LoggerService';
import { ApiRoutesEnum as ApiRoutes } from '../../routes/ApiRoutesEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { useAuthContext } from '../contexts/AuthContext';
import { getButtonPrimaryStyleClass } from '../../config/FormSettings';

interface GoogleLoginButtonProps {
    text: string;
    setIsLoading: (loading: boolean) => void;
    isLoading: boolean;
    isAuthModal?: boolean;
    setIsModalOpen?: (isModalOpen: boolean) => void;
    handleCampaignStateData?: () => void;
}

const GoogleOauthAuth: React.FC<GoogleLoginButtonProps> = ({ text, setIsLoading, isLoading, isAuthModal = false, setIsModalOpen, handleCampaignStateData }) => {
    const notificationService = NotificationService();
    const navigate = useNavigate();
    const { loadingMessage } = useLoadingMessage();
    const { login } = useAuthContext();

    const handleGoogleOauth = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            await handleGoogleSuccess(tokenResponse);
        },
        onError: (error) => {
            notificationService.setMessage("Google login failed", false);

            loggerService.error(error);

            setIsLoading(false);
        },
        flow: 'implicit'
    });

    const handleGoogleSuccess = async (tokenResponse: any) => {
        try {
            setIsLoading(true);

            const { access_token } = tokenResponse;

            const formData = {
                tokenId: access_token,
            };

            const oauthResponse = await axiosInstance.post(ApiRoutes.OAUTH_GOOGLE, formData);

            const { token, refreshToken } = oauthResponse.data;

            await login(token, refreshToken);

            if (isAuthModal) {
                if (setIsModalOpen) {
                    setIsModalOpen(false);
                }

                notificationService.setMessage("Connected", true);

                if (handleCampaignStateData) {
                    handleCampaignStateData();
                }

                //we don't need navigate, component will lose state
            }
            else {
                notificationService.setMessage("Connected", true);

                setIsLoading(false);

                navigate(AppRoutes.DASHBOARD);
            }
        } catch (error: any) {
            if (error.response && error.response?.data && error.response.data?.message && error.response.data?.status != null)
                notificationService.setMessage(error.response.data.message, error.response.data.status);
        }
    };

    return (
        <button
            disabled={isLoading}
            onClick={() => { handleGoogleOauth(); }}
            className={`w-full px-4 py-2 mb-2 ${getButtonPrimaryStyleClass(false)}`}
        >
            <FontAwesomeIcon icon={faGoogle} className="mr-2 text-google" />
            {isLoading ? loadingMessage : text}
        </button>
    );
};

export default GoogleOauthAuth;