import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { CampaignViewEnum } from '../../helpers/CampaignViewEnum';
import CampaignSettings from '../../components/campaign/CampaignSettings';
import CampaignLoading from '../../components/loadings/CampaignLoading';
import GoogleAdsense from '../../components/GoogleAdsense';
import { getAdsenseSettingsDisplayAdVertical1, getAdsenseSettingsDisplayAdVertical2, getAdsenseSettingsDisplayAdHorizontal1 } from '../../config/AdsenseSettings';
import useIsMobile from '../../hooks/useIsMobile';
import { useCampaignContext } from '../../contexts/CampaignContext';
import CampaignParticipants from '../../components/campaign/CampaignParticipants';
import CampaignWinner from '../../components/campaign/CampaignWinner';
import { useIsLoading } from '../../hooks/useIsLoading';
import { useLoadingMessage } from '../../hooks/useLoadingMessage';
import { LoadingMessageEnum } from '../../helpers/LoadingMessageEnum';
import { YesNoEnum } from '../../helpers/YesNoEnum';
import { getGenerateSimulateDataParticipants, simulateData, apiNoFetchExceptionDelayRequestsMs, apiNoFetchMaxRetry } from '../../config/envConfig';
import NotificationService from '../../services/NotificationService';
import { WinnerDTO } from '../../dto/WinnerDTO';
import loggerService from '../../services/LoggerService';
import axiosInstance, { CustomAxiosRequestConfig } from '../../auth/helpers/axiosInstance';
import { CampaignTypeEnum } from '../../helpers/CampaignTypeEnum';
import { AxiosResponse } from 'axios';
import { ApiRoutesEnum } from '../../routes/ApiRoutesEnum';
import usePreventRefreshPage from '../../hooks/usePreventRefreshPage';
import { getMaxLength } from '../../helpers/StringsHelper';

const Campaign: React.FC = () => {
  const {
    unmountCampaignRevertDefaults,
    campaignViewEnum,
    campaignStateDataDTO,
    navigateReferrer,
    setCampaignParticipants,
    setCampaignViewEnum,
  } = useCampaignContext();
  const { isUnder1200px } = useIsMobile();
  const { isLoading, setIsLoading } = useIsLoading();
  const { loadingMessage, setLoadingMessage } = useLoadingMessage();
  const navigate = useNavigate();
  const notificationService = NotificationService();
  usePreventRefreshPage();

  useEffect(() => {
    const stateValidate = async (): Promise<boolean> => {
      setIsLoading(true);

      if (!campaignStateDataDTO) {

        setIsLoading(false);

        navigate(AppRoutes.HOME);

        return false;
      }

      setIsLoading(false);

      return true;
    };

    const handleSetParticipants = async () => {
      if (!simulateData.isSimulateData) {
        if (campaignStateDataDTO?.CampaignType === CampaignTypeEnum.Comments ||
          campaignStateDataDTO?.CampaignType === CampaignTypeEnum.Reposts) {
          if (campaignStateDataDTO?.Api === YesNoEnum.No) {
            try {
              setIsLoading(true);
              setLoadingMessage("Loading participants... be patient please <3");

              const fetchCommentsWithRetry = async (url: string, params: any, retries = apiNoFetchMaxRetry, delay = apiNoFetchExceptionDelayRequestsMs): Promise<AxiosResponse> => {
                try {
                  const response = await axiosInstance.get(url,
                    {
                      params,
                      authNeeded: true
                    } as CustomAxiosRequestConfig
                  );
                  return response;
                } catch (error) {
                  if (retries > 0) {
                    await new Promise(resolve => setTimeout(resolve, delay));

                    return fetchCommentsWithRetry(url, params, retries - 1, delay);
                  }
                  else {
                    throw error;
                  }
                }
              };

              let serviceConfigurationKey = "";
              let next = "";

              while (true) {
                const response: any = await fetchCommentsWithRetry(
                  ApiRoutesEnum.CAMPAIGNS_FETCH_GET_COMMENTS,
                  {
                    serviceConfigurationKey: serviceConfigurationKey,
                    socialPlatform: campaignStateDataDTO?.SocialPlatform,
                    url: campaignStateDataDTO?.Url,
                    next: next
                  }
                );

                if (response?.data?.response?.comments) {
                  const participants: WinnerDTO[] = response?.data?.response?.comments?.map((comment: any) => ({
                    Username: getMaxLength(comment.username, 100),
                    UsernameDisplay: `${campaignStateDataDTO.UsernameDisplay}${comment.username}`,
                    ProfileImage: comment.profileImage,
                    Comment: getMaxLength(comment.comment, 200),
                    CommentLink: comment.commentLink,
                    CommentLikeCount: comment.commentLikeCount,
                    CommentsSocialIcon: campaignStateDataDTO?.CommentsSocialIcon,
                    ProfileLink: comment.profileLink,
                    SocialPlatformIcon: campaignStateDataDTO.SocialPlatformIcon,
                    SocialPlatformLikeIcon: campaignStateDataDTO.SocialPlatformLikeIcon,
                    SocialPlatformClassName: campaignStateDataDTO.SocialPlatformClassName,
                    IsValid: true,
                  }));

                  setCampaignParticipants((prevParticipants: WinnerDTO[] | null) =>
                    prevParticipants ? [...prevParticipants, ...participants] : participants
                  );
                }

                serviceConfigurationKey = response?.data?.serviceConfigurationKey;

                next = response.data?.response?.next;

                if (!next)
                  break;
              }

              setCampaignViewEnum(CampaignViewEnum.Settings);

              setIsLoading(false);

              return;
            } catch (error) {
              notificationService.setMessage("Failed to get comments, try again", false);

              loggerService.error(error);

              setIsLoading(false);

              navigateReferrer();
            }
          }
        }
        else if (campaignStateDataDTO?.CampaignType === CampaignTypeEnum.List) {
          setCampaignViewEnum(CampaignViewEnum.Settings);

          setIsLoading(false);

          return;
        }
      }
      //simulate
      else {
        setCampaignParticipants(getGenerateSimulateDataParticipants(500000));

        setCampaignViewEnum(CampaignViewEnum.Settings);

        setIsLoading(false);
      }
    };

    const init = async () => {
      if (!(await stateValidate()))
        return;

      await handleSetParticipants();
    };

    init();

    return () => {
      unmountCampaignRevertDefaults();
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Campaign</title>
      </Helmet>
      <div className="relative">

        <div className={`w-full max-w-2xl mx-auto`}>
          {isLoading ? (
            <>
              <CampaignLoading loadingMessage={loadingMessage} />
            </>
          ) : (
            <>
              {campaignViewEnum === CampaignViewEnum.Settings && (
                <>
                  <CampaignSettings />

                  <CampaignParticipants />
                </>
              )}
              {campaignViewEnum === CampaignViewEnum.Winner && (
                <>
                  <CampaignWinner />
                </>
              )}
            </>
          )}
        </div>

      </div>
    </Layout>
  );
};

export default Campaign;