import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import { RoutesEnum as AppRoutes } from '../../routes/RoutesEnum';
import { useAuthContext } from './../contexts/AuthContext';
import { useIsLoading } from '../../hooks/useIsLoading';
import LoadingScreen from '../../components/loadings/LoadingScreen';

const LoggedInNotAllowedProtectedRoute: React.FC = () => {
  const { isLoggedIn, isLoggedInState, isAllowLoggedInFromHome } = useAuthContext();
  const { isLoading, setIsLoading } = useIsLoading(true);

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      await isLoggedIn();

      setIsLoading(false);
    };

    checkIsLoggedIn();
  }, []);
  
  if (isLoading)
    return <LoadingScreen />;
  else
    return isLoggedInState ? isAllowLoggedInFromHome ? <Outlet /> : <Navigate to={AppRoutes.DASHBOARD} /> : <Outlet />;
};

export default LoggedInNotAllowedProtectedRoute;