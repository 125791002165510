import React from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';

const RandomNumbers: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Random Numbers Generator</title>
      </Helmet>
    </Layout>
  );
};

export default RandomNumbers;