import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';

const Terms: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>socialspicker.com - Terms and Conditions</title>
      </Helmet>
    </Layout>
  );
};

export default Terms;