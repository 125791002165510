import React, { useEffect, useRef } from 'react';
import { AdsenseSettingsDTO } from '../dto/AdsenseSettingsDTO';

interface GoogleAdsenseProps {
    adsenseSettingsDTO: AdsenseSettingsDTO | null;
}

const GoogleAdsense: React.FC<GoogleAdsenseProps> = ({ adsenseSettingsDTO }) => {
    const adLoadedRef = useRef(false);

    useEffect(() => {
        const loadAd = () => {
            if (!adLoadedRef.current && window.adsbygoogle && window.adsbygoogle.loaded) {
                window.adsbygoogle.push({});
                adLoadedRef.current = true;
            } else {
                setTimeout(loadAd, 1000);
            }
        };

        loadAd();
    }, []);

    const adStyle = {
        display: 'block',
        border: process.env.NODE_ENV === 'development' ? '1px solid red' : '',
    };

    return (
        <>
            {adsenseSettingsDTO && (
                <ins className="adsbygoogle min-h-[100px] max-w-full"
                    style={adStyle}
                    data-ad-client={adsenseSettingsDTO?.DataAdClient}
                    data-ad-slot={adsenseSettingsDTO?.DataAdSlot}
                    {...(adsenseSettingsDTO?.DataAdFormat && { 'data-ad-format': adsenseSettingsDTO.DataAdFormat })}
                >
                </ins>
            )}
        </>
    );
};

export default GoogleAdsense;